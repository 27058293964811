require('dotenv').config();

import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';
import 'firebase/compat/functions';

  // Your web app's Firebase configuration
  // For Firebase JS SDK v7.20.0 and later, measurementId is optional
// For Firebase JS SDK v7.20.0 and later, measurementId is optional


const firebaseConfig = {
  apiKey: "AIzaSyAcIhkPfYqeUf8Px5UK9331B61GIgy9EPk",
  authDomain: "complaia.firebaseapp.com",
  projectId: "complaia",
  storageBucket: "complaia.appspot.com",
  messagingSenderId: "534295588150",
  appId: "1:534295588150:web:bf9bb63fb32dbdd680d797",
  measurementId: "G-WKREBLGQJ2"
};





  // Initialize Firebase
firebase.initializeApp(firebaseConfig);
  
//const db = firebase.firestore()
const auth = firebase.auth();
const db = firebase.firestore();
const storage = firebase.storage();
const app = firebase.app()


export { firebase, auth, db, storage, app };