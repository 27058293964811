<template>
  <!-- Loader -->
  <transition name="fade">
    <Loader class="min-h-screen w-screen" v-if="$store.state.globalLoader.show" :header="$store.state.globalLoader.head" :key="$store.state.globalLoader.head" :subheader="$store.state.globalLoader.subheader"></Loader>
  </transition>
<div v-if="!loading && !checkComplaint" id="form" class="min-h-screen w-screen flex flex-wrap flex-col items-center justify-center" :class="$store.state.portalSettings.appearance.backgroundType.name === 'Solid' ? `bg-${($store.state.portalSettings.appearance.background.firstColorType).toLowerCase()}-${$store.state.portalSettings.appearance.background.firstColorIntense}` 
: $store.state.portalSettings.appearance.backgroundType.name === 'Gradient' ? `bg-gradient-to-r from-${($store.state.portalSettings.appearance.background.firstColorType).toLowerCase()}-${$store.state.portalSettings.appearance.background.firstColorIntense} to-${($store.state.portalSettings.appearance.background.secondColorType).toLowerCase()}-${$store.state.portalSettings.appearance.background.secondColorIntense}` : '' " >
  <div v-if="this.$store.state.portalSettings.logo.logoUrl !== undefined && this.$store.state.portalSettings.logo.logoUrl.length > 0" class="flex justify-center items-center w-full h-20 sm:h-24" ><a v-if="this.$store.state.portalSettings.logo.returnLink !== undefined && this.$store.state.portalSettings.logo.returnLink.length > 0" :href="this.$store.state.portalSettings.logo.returnLink" target="_blank"><img class="h-14 sm:h-18 w-auto" :src="this.$store.state.portalSettings.logo.logoUrl"></a> <img v-if="this.$store.state.portalSettings.logo.returnLink === undefined || this.$store.state.portalSettings.logo.returnLink.length === 0" class="h-14 sm:h-18 w-auto" :src="this.$store.state.portalSettings.logo.logoUrl"></div>
  <!-- Notifications -->
  <transition name="fade">
      <Notification v-if="$store.state.notification.show"></Notification>
  </transition>

  <!-- Steps progress bar -->
  <div :class="[this.$store.state.portalSettings.logo.logoUrl !== undefined && this.$store.state.portalSettings.logo.logoUrl.length > 0 ? '' : 'mt-16 sm:mt-20']" class="shadow-lg rounded-lg mb-16 sm:mb-20 min-w-form max-w-form sm:min-w-1 md:min-w-2 sm:max-w-none" ref="products">
    <div class="py-5 bg-white space-y-6 p-6 rounded-lg"> 
      <div class="md:pl-2 md:pr-2 flex flex-col flex-1">
        <nav aria-label="Progress" class="mt-4 shepherd-steps" ref="complaintSteps">
          <ol role="list" class="flex space-y-0 space-x-2 sm:space-x-8 select-none">
            <li v-for="step in checkSteps.preparedSteps" :key="step.name" class="flex-1 shepherd-step" ref="complaintStep">
              <a v-if="step.status === 'complete'"  class="group py-2 flex flex-col border-blue-200 pl-0 pt-4 pb-0 border-l-0 border-t-4">
                <span class="text-xs text-blue-500 font-semibold tracking-wide uppercase ">{{ step.id }}</span>
                <span class="text-xs sm:text-sm font-medium">{{ step.name }}</span>
              </a>
              <a v-else-if="step.status === 'current'"  class="py-2 flex flex-col border-blue-600 pl-0 pt-4 pb-0 border-l-0 border-t-4" aria-current="step">
                <span class="text-xs text-blue-500 font-semibold tracking-wide uppercase">{{ step.id }}</span>
                <span class="text-xs sm:text-sm font-medium">{{ step.name }}</span>
              </a>
              <a v-else class="group  py-2 flex flex-col border-gray-200 md:pl-0 pt-4 pb-0 border-l-0 border-t-4">
                <span class="text-xs text-gray-500 font-semibold tracking-wide uppercase ">{{ step.id }}</span>
                <span class="text-xs sm:text-sm font-medium">{{ step.name }}</span>
              </a>
            </li>
          </ol>
        </nav>
        <!-- Step 1 -->
        <form class="space-y-8 divide-y divide-gray-200" v-if="formStep === 1">
        <div class="space-y-8 divide-y divide-gray-200">
          
          <div class="pt-4" id="productDetails">
            <div>
              <h3 class="text-lg leading-6 font-medium text-gray-900">Complained product<span v-if="complaintTemplate.products.length > 1">s</span></h3>
              <p class="mt-1 text-sm text-gray-500">Fill in the details of the complained product<span v-if="complaintTemplate.products.length > 1">s</span>.</p>
            </div>
            <div v-for="(product, index) in complaintTemplate.products" :key="index" :id="`product${index}`" ref="stepOneProduct" class="goals-box relative mt-4 grid grid-cols-1 gap-y-4 gap-x-4 sm:grid-cols-6 border-2 border-gray-200 rounded-md  p-6">
              <div class="col-span-6" ref="productIndex">
              <h3 class="text-lg font-medium leading-6 text-gray-900">Product #{{index+1}}</h3>
              <span class="absolute right-0 top-0 py-6 pr-6">
                <img src="https://firebasestorage.googleapis.com/v0/b/complaia.appspot.com/o/webAssets%2Fexpand-button.svg?alt=media&token=539335a9-af3b-40c1-8dd8-e9658ebb4958" class="expand-icon" @click.prevent="expand(index)">
              </span>
              </div>
              <!-- Expectations -->
              <div v-if="this.$store.state.portalSettings.fields.product.expectationInput.isShowed" class="col-span-6 select-none" ref="productExpectations">
                <label :class="{'block text-sm font-medium text-gray-700 cursor-pointer': true,}" :for="`expectations${index}`" >Expectations</label>
                <!-- <select :disabled="disableTour" v-model="complaintTemplate.products[index].expectation" :id="`expectations${index}`" @change="checkExpectations()" :class="`mt-1 block w-full pl-3 pr-10 py-2 text-sm border-gray-300 focus:outline-none focus:ring-${this.$store.state.portalSettings.appearance.themeColor}-500 focus:border-${this.$store.state.portalSettings.appearance.themeColor}-500  text-sm rounded-md`">
                  <option v-for="(expectation, index) in expectations" :value="expectation" :key="index" class="text-black">{{ expectation.name }}</option>
                </select> -->
                <Listbox as="div" :disabled="disableTour" v-model="complaintTemplate.products[index].expectation" :id="`expectations${index}`">
                    <div class="mt-1 relative">
                    <ListboxButton :class="`bg-white relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-${this.$store.state.portalSettings.appearance.themeColor}-500 focus:border-${this.$store.state.portalSettings.appearance.themeColor}-500 text-sm`">
                        <span class="flex items-center truncate">{{ complaintTemplate.products[index].expectation.name }}</span>
                        <span class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                        <SelectorIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                        </span>
                    </ListboxButton>

                    <transition leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100" leave-to-class="opacity-0">
                        <ListboxOptions class="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none text-sm">
                        <ListboxOption @click.prevent="checkExpectations()" as="template" v-for="(expectation, index) in expectations" :value="expectation" :key="index" v-slot="{ active, selected }">
                            <li :class="[active ? `text-white bg-${this.$store.state.portalSettings.appearance.themeColor}-600` : 'text-gray-900', 'relative cursor-default select-none py-2 pl-3 pr-9']">
                            <span :class="[selected ? 'font-semibold' : 'font-normal', 'flex items-center truncate']">{{ expectation.name }}</span>

                            <span v-if="selected" :class="[active ? 'text-white' : `text-${this.$store.state.portalSettings.appearance.themeColor}-600`, 'absolute inset-y-0 right-0 flex items-center pr-4']">
                                <CheckIcon class="h-5 w-5" aria-hidden="true" />
                            </span>
                            </li>
                        </ListboxOption>
                        </ListboxOptions>
                    </transition>
                    </div>
                </Listbox>
              </div>
              <!-- Product name -->
              <div v-if="this.$store.state.portalSettings.fields.product.productNameInput.isShowed" class="col-span-6" ref="productName">
                <label :for="`productName${index}`" :class="{'block text-sm font-medium text-gray-700 cursor-pointer': true, 'text-red-500': errorsStep1[index].productName}"> Product name<span v-if="this.$store.state.portalSettings.fields.product.productNameInput.isRequired">*</span> <span v-else class="text-xs opacity-70"> (Optional)</span> </label>
                <div class="mt-1">
                  <input :disabled="disableTour" v-model="complaintTemplate.products[index].name" type="text" :id="`productName${index}`"  :class="['shadow-sm  block w-full text-sm rounded-md', !errorsStep1[index].productName ? `border-gray-300 focus:ring-${this.$store.state.portalSettings.appearance.themeColor}-500 focus:border-${this.$store.state.portalSettings.appearance.themeColor}-500` : 'border-red-600 focus:ring-red-500 focus:border-red-500']" />
                </div>
                <div class="text-red-500 text-xs pl-1 pt-1" v-if="errorsStep1Text[index].productName.length > 0">
                    {{ errorsStep1Text[index].productName }}
                </div>
              </div>
              <!-- Description -->
              <div v-if="this.$store.state.portalSettings.fields.product.descriptionInput.isShowed" class="col-span-6" ref="productDescription">
                <label :for="`problemDescription${index}`" :class="{'block text-sm font-medium text-gray-700 cursor-pointer': true, 'text-red-500': errorsStep1[index].problemDescription}">Problem description<span v-if="this.$store.state.portalSettings.fields.product.descriptionInput.isRequired">*</span> <span v-else class="text-xs opacity-70"> (Optional)</span></label>
                <div class="mt-1">
                  <textarea :disabled="disableTour" rows="4" v-model="complaintTemplate.products[index].problemDescription" :id="`problemDescription${index}`" :class="['shadow-sm  block w-full text-sm rounded-md', !errorsStep1[index].problemDescription ? `border-gray-300 focus:ring-${this.$store.state.portalSettings.appearance.themeColor}-500 focus:border-${this.$store.state.portalSettings.appearance.themeColor}-500` : 'border-red-600 focus:ring-red-500 focus:border-red-500']" />
                </div>
                <div class="text-red-500 text-xs pl-1 pt-1" v-if="errorsStep1Text[index].problemDescription.length > 0">
                    {{ errorsStep1Text[index].problemDescription }}
                </div>
              </div>
              <!-- Invoice Number -->
              <div v-if="this.$store.state.portalSettings.fields.product.invoiceNumberInput.isShowed" class="col-span-6" ref="productInvoice">
                <label :for="`invoiceNumber${index}`" :class="{'block text-sm font-medium text-gray-700 cursor-pointer': true, 'text-red-500': errorsStep1[index].invoiceNumber}">{{this.$store.state.portalSettings.fields.product.invoiceNumberInput.fieldName}}<span v-if="this.$store.state.portalSettings.fields.product.invoiceNumberInput.isRequired">*</span> <span v-else class="text-xs opacity-70"> (Optional)</span></label>
                <div class="mt-1">
                  <input :disabled="disableTour" v-model="complaintTemplate.products[index].invoiceNumber" type="text" :id="`invoiceNumber${index}`" :class="['shadow-sm  block w-full text-sm rounded-md', !errorsStep1[index].invoiceNumber ? `border-gray-300 focus:ring-${this.$store.state.portalSettings.appearance.themeColor}-500 focus:border-${this.$store.state.portalSettings.appearance.themeColor}-500` : 'border-red-600 focus:ring-red-500 focus:border-red-500']" />
                </div>
                <div class="text-red-500 text-xs pl-1 pt-1" v-if="errorsStep1Text[index].invoiceNumber.length > 0">
                    {{ errorsStep1Text[index].invoiceNumber }}
                </div>
              </div>
              <!-- Images -->
              <div class="col-span-6">
                <div v-if="this.$store.state.portalSettings.fields.product.imgInput.isShowed" class="mt-2 select-none mb-2" ref="productImages">
                  <label :class="{'block text-sm font-medium text-gray-700 mb-2': true, 'text-red-500': errorsStep1[index].img}">Images<span v-if="this.$store.state.portalSettings.fields.product.imgInput.isRequired">*</span> <span v-else class="text-xs opacity-70"> (Optional)</span></label>
                  <div class="flex items-center" v-if="imgHolder[complaintTemplate.products[index].id].length === 0">
                    <CameraIcon :class="{'h-4 w-4 ml-0.1 mr-1 opacity-40': true, 'text-red-500 opacity-80': errorsStep1[index].img}" aria-hidden="true" />
                    <span :class="{'text-xs opacity-40': true, 'text-red-500 opacity-80': errorsStep1[index].img}">Images not added..</span>
                    <span :class="['transition ease-in-out duration-300 text-xs ml-4 text-purple-500 underline hover:text-purple-700 cursor-pointer', disableTour ? 'pointer-events-none' : '']" @click.prevent="triggerImgUploaderClick(`imgUpldr${index}`)">Attach</span>
                  </div>
                  <div class="flex items-center" v-for="(fl,flNdx) in imgHolder[complaintTemplate.products[index].id]" :key="flNdx">
                    <CameraIcon class="h-4 w-4 ml-0.1 mr-1 opacity-60" aria-hidden="true" />
                    <span class="text-xs opacity-60">{{fl.file.name.substring(0,100)}}</span>
                    <span class="transition ease-in-out duration-300 text-xs ml-4 text-purple-500 underline hover:text-purple-700 cursor-pointer" @click.prevent="imgDelete(imgHolder[complaintTemplate.products[index].id],flNdx)">Delete</span>
                  </div>
                  <div class="text-red-500 text-xs pl-1 pt-2 pb-2" v-if="errorsStep1Text[index].img.length > 0">
                    {{ errorsStep1Text[index].img }}
                  </div>
                </div>
                <!-- PDF -->
                <div v-if="this.$store.state.portalSettings.fields.product.pdfInput.isShowed" class="mt-2 select-none mb-2" ref="productPDF">
                  <label :class="{'block text-sm font-medium text-gray-700 mb-2': true, 'text-red-500': errorsStep1[index].pdf}">PDF<span v-if="this.$store.state.portalSettings.fields.product.pdfInput.isRequired">*</span> <span v-else class="text-xs opacity-70"> (Optional)</span></label>
                  <div class="flex items-center" v-if="pdfHolder[complaintTemplate.products[index].id].length === 0">
                    <DocumentIcon :class="{'h-4 w-4 mr-1 opacity-40': true, 'text-red-500 opacity-80': errorsStep1[index].pdf}" aria-hidden="true" />
                    <span :class="{'text-xs opacity-40': true, 'text-red-500 opacity-80': errorsStep1[index].pdf}">PDF file not selected..</span>
                    <span :class="['transition ease-in-out duration-300 text-xs ml-4 text-purple-500 underline hover:text-purple-700 cursor-pointer', disableTour ? 'pointer-events-none' : '']" @click.prevent="triggerPDFUploaderClick(`pdfUpldr${index}`)">Attach</span>
                  </div>
                  <div class="flex items-center" v-for="(pdf,pdfIndx) in pdfHolder[complaintTemplate.products[index].id]" :key="pdfIndx">
                    <DocumentIcon class="h-4 w-4 mr-1 opacity-60" aria-hidden="true" />
                    <span class="text-xs opacity-60">{{pdf.file.name.substring(0,100)}}</span>
                    <span class="transition ease-in-out duration-300 text-xs ml-4 text-purple-500 underline hover:text-purple-700 cursor-pointer" @click.prevent="pdfHolder[complaintTemplate.products[index].id].splice(pdfIndx,1)">Delete</span>
                  </div>
                  <div class="text-red-500 text-xs pl-1 pt-2 pb-2" v-if="errorsStep1Text[index].pdf.length > 0">
                    {{ errorsStep1Text[index].pdf }}
                  </div>
                </div>
                <!-- Buttons -->
                <div class="flex flex-col sm:flex-row sm:justify-end">
                  <input v-if="this.$store.state.portalSettings.fields.product.imgInput.isShowed" type="file" accept="image/*" v-show="false" :ref="`imgUpldr${index}`" @change="addToImgHolder($event,complaintTemplate.products[index].id)">
                  <input v-if="this.$store.state.portalSettings.fields.product.pdfInput.isShowed" type="file" accept="application/pdf" v-show="false" :ref="`pdfUpldr${index}`" @change="addToPDFHolder($event,complaintTemplate.products[index].id)">  
                  <button :disabled="disableTour" ref="pdfButton" v-if="this.$store.state.portalSettings.fields.product.pdfInput.isShowed" type="button" @click.prevent="triggerPDFUploaderClick(`pdfUpldr${index}`)" :class="{'transition ease-in-out duration-300 mt-3 sm:mt-2 sm:mr-2 inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded focus:outline-none focus:ring-2 focus:ring-offset-2 text-blue-700 hover:bg-blue-200 bg-blue-100 focus:ring-blue-500': true, 'opacity-30' : false}"><DocumentIcon class="h-4 w-4 mr-1" aria-hidden="true" />Attach PDF</button>
                  <button :disabled="disableTour" ref="imgButton" v-if="this.$store.state.portalSettings.fields.product.imgInput.isShowed" type="button" @click.prevent="triggerImgUploaderClick(`imgUpldr${index}`, this.imgQty - imgHolder[complaintTemplate.products[index].id].length)" :class="{'transition ease-in-out duration-300 mt-3 sm:mt-2 sm:mr-2 inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded focus:outline-none focus:ring-2 focus:ring-offset-2 text-blue-700 hover:bg-blue-200 bg-blue-100 focus:ring-blue-500': true, 'opacity-30' : this.imgQty - imgHolder[complaintTemplate.products[index].id].length === 0}"><CameraIcon class="h-4 w-4 mr-1" aria-hidden="true" />Add image ({{this.imgQty - imgHolder[complaintTemplate.products[index].id].length}} left)</button>
                  <button :disabled="disableTour" ref="delButton" @click.prevent="deleteProduct(index)" type="button" class="transition ease-in-out duration-300 mt-3 sm:mt-2 inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-red-700 bg-red-100 hover:bg-red-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500">Delete product</button>
                </div>
              </div>
            </div>
            <!-- Add new product -->
            <button :disabled="disableTour" ref="addNewProduct" type="button" @click.prevent="addNewProduct()" :class="{'transition ease-in-out duration-300 relative mt-4 block w-full border-2 border-purple-200 border-dashed rounded-md pb-4 pt-4 text-center hover:border-purple-300': true, 'border-red-500 hover:border-red-600': errors.noAnswers === true}">
              <svg :class="{'mx-auto h-8 w-8 text-purple-300': true, 'text-red-600': errors.noAnswers === true}" xmlns="http://www.w3.org/2000/svg" stroke="currentColor" fill="none" viewBox="0 0 48 48" aria-hidden="true">
                <ClipboardListIcon></ClipboardListIcon>
              </svg>
              <span :class="{'mt-2 block text-sm font-medium text-purple-300 ': true, 'text-red-500': errors.noAnswers === true}"> Add <span v-if="complaintTemplate.products.length === 0">first </span><span v-if="complaintTemplate.products.length > 0">another </span>product </span>
            </button>
          </div>
        </div>

        <div class="pt-5">
          <div class="flex justify-end">
            <button :disabled="disableTour" ref="firstStepNextStep" @click.prevent="checkErrors(1)" class="transition ease-in-out duration-300 ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white  focus:outline-none focus:ring-2 focus:ring-offset-2" :class="`focus:ring-${this.$store.state.portalSettings.appearance.themeColor}-400 bg-${this.$store.state.portalSettings.appearance.themeColor}-500 hover:bg-${this.$store.state.portalSettings.appearance.themeColor}-600`">Next step</button>
          </div>
        </div>
      </form>

      <!-- Step 2 -->
        <form class="space-y-8 divide-y divide-gray-200" v-if="formStep === 2">
        <div class="space-y-8 divide-y divide-gray-200">
        
          <div class="pt-4" id="clientCredentials">
            <div>
              <h3 class="text-lg leading-6 font-medium text-gray-900">Client credentials</h3>
              <p class="mt-1 text-sm text-gray-500">Fill out your credentials.</p>
            </div>
            <div class="mt-4 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6" ref="stepSecondProduct">
                <!-- Name -->
                <div v-if="this.$store.state.portalSettings.fields.credentials.nameInput.isShowed" class="sm:col-span-6" ref="stepSecondName">
                  <label for="name" :class="{'block text-sm font-medium text-gray-700 cursor-pointer': true, 'text-red-500': errorsStep2[0].name}"> Name<span v-if="this.$store.state.portalSettings.fields.credentials.nameInput.isRequired">*</span> <span v-else class="text-xs opacity-70"> (Optional)</span> </label>
                  <div class="mt-1">
                    <input :disabled="disableTour" v-model="complaintTemplate.clientCredentials.name" type="text" id="name" :class="['shadow-sm  block w-full text-sm rounded-md', !errorsStep2[0].name ? `border-gray-300 focus:ring-${this.$store.state.portalSettings.appearance.themeColor}-500 focus:border-${this.$store.state.portalSettings.appearance.themeColor}-500` : 'border-red-600 focus:ring-red-500 focus:border-red-500']" />
                  </div>
                  <div class="text-red-500 text-xs pl-1 pt-1" v-if="errorsStep2Text[0].name.length > 0">
                      {{ errorsStep2Text[0].name }}
                  </div>
                </div>
                <!-- E-mail -->
                <div v-if="this.$store.state.portalSettings.fields.credentials.emailInput.isShowed" class="sm:col-span-6" ref="stepSecondEmail">
                  <label for="email" :class="{'block text-sm font-medium text-gray-700 cursor-pointer': true, 'text-red-500': errorsStep2[0].email}"> E-mail<span v-if="this.$store.state.portalSettings.fields.credentials.emailInput.isRequired">*</span> <span v-else class="text-xs opacity-70"> (Optional)</span> </label>
                  <div class="mt-1">
                    <input :disabled="disableTour" v-model="complaintTemplate.clientCredentials.email" type="text" id="email" :class="['shadow-sm  block w-full text-sm rounded-md', !errorsStep2[0].email ? `border-gray-300 focus:ring-${this.$store.state.portalSettings.appearance.themeColor}-500 focus:border-${this.$store.state.portalSettings.appearance.themeColor}-500` : 'border-red-600 focus:ring-red-500 focus:border-red-500']" />
                  </div>
                  <div class="text-red-500 text-xs pl-1 pt-1" v-if="errorsStep2Text[0].email.length > 0">
                      {{ errorsStep2Text[0].email }}
                  </div>
                </div>
                <!-- Phone -->
                <div v-if="this.$store.state.portalSettings.fields.credentials.phoneInput.isShowed" class="sm:col-span-6" ref="stepSecondPhone">
                  <label for="phone" :class="{'block text-sm font-medium text-gray-700 cursor-pointer': true, 'text-red-500': errorsStep2[0].phone}"> Phone<span v-if="this.$store.state.portalSettings.fields.credentials.phoneInput.isRequired">*</span> <span v-else class="text-xs opacity-70"> (Optional)</span></label>
                  <div class="mt-1">
                    <input :disabled="disableTour" v-model="complaintTemplate.clientCredentials.phone" type="text" id="phone" onkeypress='return event.charCode >= 48 && event.charCode <= 57 || event.charCode === 43 || event.charCode === 45' :class="['shadow-sm  block w-full text-sm rounded-md', !errorsStep2[0].phone ? `border-gray-300 focus:ring-${this.$store.state.portalSettings.appearance.themeColor}-500 focus:border-${this.$store.state.portalSettings.appearance.themeColor}-500` : 'border-red-600 focus:ring-red-500 focus:border-red-500']" />
                  </div>
                  <div class="text-red-500 text-xs pl-1 pt-1" v-if="errorsStep2Text[0].phone.length > 0">
                      {{ errorsStep2Text[0].phone }}
                  </div>
                </div>
                <!-- Country -->
                <div v-if="this.$store.state.portalSettings.fields.credentials.countryInput.isShowed" class="sm:col-span-6" ref="stepSecondCountry">
                  <label for="country" :class="{'block text-sm font-medium text-gray-700 cursor-pointer': true, 'text-red-500': errorsStep2[0].country}"> Country<span v-if="this.$store.state.portalSettings.fields.credentials.countryInput.isRequired">*</span> <span v-else class="text-xs opacity-70"> (Optional)</span> </label>
                  <div class="mt-1">
                    <input :disabled="disableTour" v-model="complaintTemplate.clientCredentials.country" type="text" id="country" :class="['shadow-sm  block w-full text-sm rounded-md', !errorsStep2[0].country ? `border-gray-300 focus:ring-${this.$store.state.portalSettings.appearance.themeColor}-500 focus:border-${this.$store.state.portalSettings.appearance.themeColor}-500` : 'border-red-600 focus:ring-red-500 focus:border-red-500']" />
                  </div>
                  <div class="text-red-500 text-xs pl-1 pt-1" v-if="errorsStep2Text[0].country.length > 0">
                      {{ errorsStep2Text[0].country }}
                  </div>
                </div>
                <!-- City -->
                <div v-if="this.$store.state.portalSettings.fields.credentials.cityInput.isShowed" class="sm:col-span-3" ref="stepSecondCity">
                  <label for="city" :class="{'block text-sm font-medium text-gray-700 cursor-pointer': true, 'text-red-500': errorsStep2[0].city}"> City<span v-if="this.$store.state.portalSettings.fields.credentials.cityInput.isRequired">*</span> <span v-else class="text-xs opacity-70"> (Optional)</span> </label>
                  <div class="mt-1">
                    <input :disabled="disableTour" v-model="complaintTemplate.clientCredentials.city" type="text" id="city" :class="['shadow-sm  block w-full text-sm rounded-md', !errorsStep2[0].city ? `border-gray-300 focus:ring-${this.$store.state.portalSettings.appearance.themeColor}-500 focus:border-${this.$store.state.portalSettings.appearance.themeColor}-500` : 'border-red-600 focus:ring-red-500 focus:border-red-500']"  />
                  </div>
                  <div class="text-red-500 text-xs pl-1 pt-1" v-if="errorsStep2Text[0].city.length > 0">
                      {{ errorsStep2Text[0].city }}
                  </div>
                </div>
                <!-- ZIP / Postal code -->
                <div v-if="this.$store.state.portalSettings.fields.credentials.postCodeInput.isShowed" class="sm:col-span-3" ref="stepSecondPostcode">
                  <label for="postCode" :class="{'block text-sm font-medium text-gray-700 cursor-pointer': true, 'text-red-500': errorsStep2[0].postCode}"> ZIP / Postal code<span v-if="this.$store.state.portalSettings.fields.credentials.postCodeInput.isRequired">*</span> <span v-else class="text-xs opacity-70"> (Optional)</span> </label>
                  <div class="mt-1">
                    <input :disabled="disableTour" v-model="complaintTemplate.clientCredentials.postCode" type="text" id="postCode" :class="['shadow-sm  block w-full text-sm rounded-md', !errorsStep2[0].postCode ? `border-gray-300 focus:ring-${this.$store.state.portalSettings.appearance.themeColor}-500 focus:border-${this.$store.state.portalSettings.appearance.themeColor}-500` : 'border-red-600 focus:ring-red-500 focus:border-red-500']"  />
                  </div>
                  <div class="text-red-500 text-xs pl-1 pt-1" v-if="errorsStep2Text[0].postCode.length > 0">
                      {{ errorsStep2Text[0].postCode }}
                  </div>
                </div>
                <!-- Street address -->
                <div v-if="this.$store.state.portalSettings.fields.credentials.streetAddressInput.isShowed" class="sm:col-span-6" ref="stepSecondStreetAdress">
                  <label for="streetAddress" :class="{'block text-sm font-medium text-gray-700 cursor-pointer': true, 'text-red-500': errorsStep2[0].streetAddress}"> Street address<span v-if="this.$store.state.portalSettings.fields.credentials.streetAddressInput.isRequired">*</span> <span v-else class="text-xs opacity-70"> (Optional)</span> </label>
                  <div class="mt-1">
                    <input :disabled="disableTour" v-model="complaintTemplate.clientCredentials.streetAddress" id="streetAddress" type="text" :class="['shadow-sm  block w-full text-sm rounded-md', !errorsStep2[0].streetAddress ? `border-gray-300 focus:ring-${this.$store.state.portalSettings.appearance.themeColor}-500 focus:border-${this.$store.state.portalSettings.appearance.themeColor}-500` : 'border-red-600 focus:ring-red-500 focus:border-red-500']"  />
                  </div>
                  <div class="text-red-500 text-xs pl-1 pt-1" v-if="errorsStep2Text[0].streetAddress.length > 0">
                      {{ errorsStep2Text[0].streetAddress }}
                  </div>
                </div>
            </div>
          </div>

        </div>
        <!-- Buttons -->
        <div class="pt-5">
          <div class="flex justify-end">
            <button :disabled="disableTour" type="button" ref="stepSecondpreviousStep" @click.prevent="previousStep" class="transition ease-in-out duration-300 bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500">Previous step</button>
            <button :disabled="disableTour" ref="stepSecondNextStep" @click.prevent="checkErrors(2)" class="transition ease-in-out duration-300 ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white focus:outline-none focus:ring-2 focus:ring-offset-2" :class="`focus:ring-${this.$store.state.portalSettings.appearance.themeColor}-400 bg-${this.$store.state.portalSettings.appearance.themeColor}-500 hover:bg-${this.$store.state.portalSettings.appearance.themeColor}-600`">{{ checkSteps.booleanSteps ? 'Send form' : 'Next step' }} </button>
          </div>
        </div>
      </form>

      <!-- Step 3 -->
        <form class="space-y-8 divide-y divide-gray-200" v-if="formStep === 3">
        <div class="space-y-8 divide-y divide-gray-200">
          
          <div class="pt-4" id="productDetails">
            <div class="sm:flex sm:justify-between sm:items-center">
              <div>
                <h3 class="text-lg leading-6 font-medium text-gray-900">Delivery details</h3>
                <p class="mt-1 text-sm text-gray-500">Fill out your delivery details.</p>
              </div>
              <button v-if="this.steps[2].name !== 'Chargeback' && checkSameDeliveryAddress" @click.prevent="sameAddressDelivery()" class="mt-2 sm:mt-0 transition ease-in-out duration-300 inline-flex justify-center py-1 px-2 border border-transparent shadow-sm text-xs font-medium rounded-md text-white focus:outline-none focus:ring-2 focus:ring-offset-2" :class="`focus:ring-${this.$store.state.portalSettings.appearance.themeColor}-400 bg-${this.$store.state.portalSettings.appearance.themeColor}-500 hover:bg-${this.$store.state.portalSettings.appearance.themeColor}-600`">Copy from previous step</button>
            </div>
            <div class="mt-2 sm:mt-4 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6" ref="thirdStepForm">
              <!-- <div class="sm:col-span-6 flex items-center">
                <input v-if="this.steps[2].name !== 'Chargeback' && checkSameDeliveryAddress" @click="sameAddressDelivery($event)" v-model="complaintTemplate.clientCredentials.sameDeliveryAddress" type="checkbox" id="sameDeliveryAddress" class="cursor-pointer w-4 h-4 mr-3 text-blue-600 bg-white rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                <label v-if="this.steps[2].name !== 'Chargeback' && checkSameDeliveryAddress" for="sameDeliveryAddress" :class="{'block text-sm font-medium text-gray-700 cursor-pointer': true}"> Fill fields with data from previous step </label>
              </div> -->
              <!-- Country -->
              <div class="sm:col-span-6" v-if="this.steps[2].name !== 'Chargeback' && this.$store.state.portalSettings.fields.deliveryChargeback.countryInput.isShowed">
                  <label for="country" :class="{'block text-sm font-medium text-gray-700 cursor-pointer': true, 'text-red-500': errorsStep3[0].country}"> Country<span v-if="this.$store.state.portalSettings.fields.deliveryChargeback.countryInput.isRequired">*</span> <span v-else class="text-xs opacity-70"> (Optional)</span> </label>
                  <div class="mt-1">
                    <input :disabled="disableTour" v-model="complaintTemplate.delivery.country" type="text" id="country" :class="['shadow-sm  block w-full text-sm rounded-md', !errorsStep3[0].country ? `border-gray-300 focus:ring-${this.$store.state.portalSettings.appearance.themeColor}-500 focus:border-${this.$store.state.portalSettings.appearance.themeColor}-500` : 'border-red-600 focus:ring-red-500 focus:border-red-500']" />
                  </div>
                  <div class="text-red-500 text-xs pl-1 pt-1" v-if="errorsStep3Text[0].country.length > 0">
                      {{ errorsStep3Text[0].country }}
                  </div>
                </div>
                <!-- City -->
                <div class="sm:col-span-3" v-if="this.steps[2].name !== 'Chargeback' && this.$store.state.portalSettings.fields.deliveryChargeback.cityInput.isShowed">
                  <label for="city" :class="{'block text-sm font-medium text-gray-700 cursor-pointer': true, 'text-red-500': errorsStep3[0].city}"> City<span v-if="this.$store.state.portalSettings.fields.deliveryChargeback.cityInput.isRequired">*</span> <span v-else class="text-xs opacity-70"> (Optional)</span> </label>
                  <div class="mt-1">
                    <input :disabled="disableTour" v-model="complaintTemplate.delivery.city" type="text" id="city" :class="['shadow-sm  block w-full text-sm rounded-md', !errorsStep3[0].city ? `border-gray-300 focus:ring-${this.$store.state.portalSettings.appearance.themeColor}-500 focus:border-${this.$store.state.portalSettings.appearance.themeColor}-500` : 'border-red-600 focus:ring-red-500 focus:border-red-500']" />
                  </div>
                  <div class="text-red-500 text-xs pl-1 pt-1" v-if="errorsStep3Text[0].city.length > 0">
                      {{ errorsStep3Text[0].city }}
                  </div>
                </div>
                <!-- ZIP / Postal code -->
                <div class="sm:col-span-3" v-if="this.steps[2].name !== 'Chargeback' && this.$store.state.portalSettings.fields.deliveryChargeback.postCodeInput.isShowed">
                  <label for="postCode" :class="{'block text-sm font-medium text-gray-700 cursor-pointer': true, 'text-red-500': errorsStep3[0].postCode}"> ZIP / Postal code<span v-if="this.$store.state.portalSettings.fields.deliveryChargeback.postCodeInput.isRequired">*</span> <span v-else class="text-xs opacity-70"> (Optional)</span> </label>
                  <div class="mt-1">
                    <input :disabled="disableTour" v-model="complaintTemplate.delivery.postCode" type="text" id="postCode" :class="['shadow-sm  block w-full text-sm rounded-md', !errorsStep3[0].postCode ? `border-gray-300 focus:ring-${this.$store.state.portalSettings.appearance.themeColor}-500 focus:border-${this.$store.state.portalSettings.appearance.themeColor}-500` : 'border-red-600 focus:ring-red-500 focus:border-red-500']"/>
                  </div>
                  <div class="text-red-500 text-xs pl-1 pt-1" v-if="errorsStep3Text[0].postCode.length > 0">
                      {{ errorsStep3Text[0].postCode }}
                  </div>
                </div>
                <!-- Street address -->
                <div class="sm:col-span-6" v-if="this.steps[2].name !== 'Chargeback' && this.$store.state.portalSettings.fields.deliveryChargeback.streetAddressInput.isShowed">
                  <label for="streetAddress" :class="{'block text-sm font-medium text-gray-700 cursor-pointer': true, 'text-red-500': errorsStep3[0].streetAddress}"> Street address<span v-if="this.$store.state.portalSettings.fields.deliveryChargeback.streetAddressInput.isRequired">*</span> <span v-else class="text-xs opacity-70"> (Optional)</span> </label>
                  <div class="mt-1">
                    <input :disabled="disableTour" v-model="complaintTemplate.delivery.streetAddress" id="streetAddress" type="text" :class="['shadow-sm  block w-full text-sm rounded-md', !errorsStep3[0].streetAddress ? `border-gray-300 focus:ring-${this.$store.state.portalSettings.appearance.themeColor}-500 focus:border-${this.$store.state.portalSettings.appearance.themeColor}-500` : 'border-red-600 focus:ring-red-500 focus:border-red-500']" />
                  </div>
                  <div class="text-red-500 text-xs pl-1 pt-1" v-if="errorsStep3Text[0].streetAddress.length > 0">
                      {{ errorsStep3Text[0].streetAddress }}
                  </div>
                </div>
                <!-- Bank account number -->
                <div class="sm:col-span-6" v-if="(steps[2].name === 'Chargeback' || steps[2].name === 'Chargeback / Delivery') && this.$store.state.portalSettings.fields.deliveryChargeback.bankAccountNumberInput.isShowed">
                  <label for="accountNumber" :class="{'block text-sm font-medium text-gray-700 cursor-pointer': true, 'text-red-500': errorsStep3[0].accountNumber}"> Bank account number<span v-if="this.$store.state.portalSettings.fields.deliveryChargeback.bankAccountNumberInput.isRequired">*</span> <span v-else class="text-xs opacity-70"> (Optional)</span> </label>
                  <div class="mt-1">
                    <input :disabled="disableTour" v-model="complaintTemplate.clientCredentials.accountNumber" type="text" id="accountNumber" :class="['shadow-sm  block w-full text-sm rounded-md', !errorsStep3[0].accountNumber ? `border-gray-300 focus:ring-${this.$store.state.portalSettings.appearance.themeColor}-500 focus:border-${this.$store.state.portalSettings.appearance.themeColor}-500` : 'border-red-600 focus:ring-red-500 focus:border-red-500']"/>
                  </div>
                  <div class="text-red-500 text-xs pl-1 pt-1" v-if="errorsStep3Text[0].accountNumber.length > 0">
                      {{ errorsStep3Text[0].accountNumber }}
                  </div>
                </div>
            </div>
          </div>
        </div>
        <!-- Buttons -->
        <div class="pt-5">
          <div class="flex justify-end">
            <button :disabled="disableTour" type="button" @click.prevent="previousStep" class="transition ease-in-out duration-300 bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500">Previous step</button>
            <button :disabled="disableTour" @click.prevent="checkErrors(3)" class="transition ease-in-out duration-300 ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white focus:outline-none focus:ring-2 focus:ring-offset-2" :class="`focus:ring-${this.$store.state.portalSettings.appearance.themeColor}-400 bg-${this.$store.state.portalSettings.appearance.themeColor}-500 hover:bg-${this.$store.state.portalSettings.appearance.themeColor}-600`">Send form</button>
          </div>
        </div>
      </form>
      </div>
      <!-- <checkComplaint v-if="checkComplaint"></checkComplaint> -->
    </div>
  </div>
  <footer class="w-full mt-auto shadow-lg_footer" aria-labelledby="footer-heading">
    <div class="transition ease-in-out duration-200 px-4 bg-black opacity-60 space-y-6 p-2 hover:opacity-70">  
    <div class="mt-auto flex justify-center items-center flex-col">
      <a class="text-gray-100 text-center text-sm" href="https://complaia.com/">Powered by Complaia 2022.</a>
    </div>
  </div>
  </footer>
</div>
<checkComplaint v-if="!loading && checkComplaint" :complaintData="complaintData" :loading="loading" @addMessage="addMessage"></checkComplaint>
<AlertModal v-if="showAlert" :buttonColor="'purple'" :iconColor="'yellow'" :showCancelButton="false" :modalData="alertData" @accept="createTour()"></AlertModal>

</template>

<script>
    import AlertModal from '../components/AlertModal.vue'
    import axios from 'axios';
    //import moment from 'moment';
    //import EmptyState from '../components/EmptyState.vue';
    import {storage} from "@/firebase/gfbconf.js";
    import { Listbox, ListboxButton, ListboxOption, ListboxOptions } from '@headlessui/vue'
    import { DocumentIcon, CameraIcon, ClipboardListIcon, CheckIcon } from '@heroicons/vue/outline'
    import {SelectorIcon} from "@heroicons/vue/solid"
    import generateApiKey from 'generate-api-key';
    import Notification from '../components/Notification.vue';
    import checkComplaint from '../components/checkComplaint.vue';
    import Loader from '../components/Loader.vue'
    // import moment from 'moment';
    export default { 
        name: "NewComplaint",
        components: {
          Listbox,
          ListboxButton,
          ListboxOption,
          ListboxOptions,
          CheckIcon,
          SelectorIcon,
          //EmptyState,
          //PlusSmIcon,
          // SearchIcon,
          AlertModal,
          CameraIcon,
          DocumentIcon,
          // ClipboardCopyIcon,
          ClipboardListIcon,
          Notification,
          Loader,
          checkComplaint
        },
        data()
        {
            return {
              disableTour: false,
              showAlert: false,
              alertData: {
                headerText: "Here for the first time?",
                bodyText: "If you want you can use our tutorial, which will guide you on how to use the form correctly, either you can cancel it or proceed to filling it out.",
                acceptButtonText: "I understand"
              },
              tour: null,
              loading: true,
              imgHolder: {},
              pdfHolder: {},
              // Img upload settings
              perImgSizeMB: 4,
              imgQty: 2,
              // Pdf uplaod settings
              perPDFSizeMB: 2,
              steps: [{name: "Select products", status: "current", id: "Step 1"},{name: "Credentials", status: "", id: "Step 2"},{name: "", status: "", id: "Step 3"}],
              formStep: 1,
              expectations: [],
              errors: {
                productName: false,
                problemDescription: false,
                invoiceNumber: false,
              },
              errorsStep1: [],
              errorsStep1Text: [],
              errorsStep2: [],
              errorsStep2Text: [],
              errorsStep3: [],
              errorsStep3Text: [],
              errorProduct: null,
              complaintTemplate: {
                products: [],
                formId: generateApiKey({ method: 'string', pool: "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789", length: 16 }),
                delivery: {
                  streetAddress: "",
                  city: "",
                  postCode: "",
                  country: "",
                },
                clientCredentials: {
                  name: "",
                  email: "",
                  phone: "",
                  streetAddress: "",
                  city: "",
                  postCode: "",
                  country: "",
                  sameDeliveryAddress: false,
                  accountNumber: "",
                }
              },
              checkComplaint: false,
              complaintData: {}
            }
        },
        methods:
        {
          sameAddressDelivery() {
            this.complaintTemplate.clientCredentials.sameDeliveryAddress = !this.complaintTemplate.clientCredentials.sameDeliveryAddress
            if(this.complaintTemplate.clientCredentials.sameDeliveryAddress) {
              this.complaintTemplate.delivery.streetAddress = this.complaintTemplate.clientCredentials.streetAddress
              this.complaintTemplate.delivery.city = this.complaintTemplate.clientCredentials.city
              this.complaintTemplate.delivery.postCode = this.complaintTemplate.clientCredentials.postCode
              this.complaintTemplate.delivery.country = this.complaintTemplate.clientCredentials.country
            } else {
              this.complaintTemplate.delivery.streetAddress = ""
              this.complaintTemplate.delivery.city = ""
              this.complaintTemplate.delivery.postCode = ""
              this.complaintTemplate.delivery.country = ""
            }
          },
          async createTour() {
            this.tour = await this.$shepherd({
              canClickTarget: false,
              keyboardNavigation: false,
              exitOnEsc: false,
              defaultStepOptions: {
                scrollTo: { behavior: 'smooth', block: 'center' },
              },
              useModalOverlay: {
                enabled: true
              }
            })
            this.tour.addStep({
              title: 'Hi there!',
              text: 'Welcome to the tutorial on how to use our Complaia complaint form.',
                buttons: [
                  {
                    text: 'Back',
                    classes: 'shepherd-button-secondary',
                    action: this.tour.cancel,
                    disabled: true,
                  },
                  {
                    text: 'Next',
                    classes: 'shepherd-button-secondary',
                    action: this.tour.next
                  },
                ]
            });
            this.tour.addStep({
              modalOverlayOpeningRadius: 5,
              popperOptions: {
                modifiers: [{ name: 'offset', options: { offset: [0, 4] } }]
              },
              text: 'This is the form we wrote to make a complaint run smoothly between you and the seller. Just fill in the information in the required fields about the product, and you are done. Click "next" to see next step.',
                attachTo: {
                  element: this.$refs.products,
                  on: 'left'
                },
                buttons: [
                    {
                      text: 'Back',
                        classes: 'shepherd-button-secondary',
                        action: this.tour.back
                    },
                    {
                      text: 'Next',
                        classes: 'shepherd-button-secondary',
                        action: this.tour.next
                    }
                ]
            });
            this.tour.addStep({
              modalOverlayOpeningPadding: 10,
              modalOverlayOpeningRadius: 5,
              popperOptions: {
                modifiers: [{ name: 'offset', options: { offset: [0, 16] } }]
              },
              title: 'Steps',
                text: 'At the top of the form, there are three steps that you will go through from beginning to end by completing the required fields',
                classes: 'rounded-lg',
                attachTo: {
                  element: this.$refs.complaintSteps,
                    on: 'bottom'
                },
                buttons: [
                  {
                    text: 'Back',
                        classes: 'shepherd-button-secondary',
                        action: this.tour.back
                    },
                    {
                      text: 'Next',
                        classes: 'shepherd-button-secondary',
                        action: this.tour.next
                    }
                ]
            });
            this.tour.addStep({
              modalOverlayOpeningPadding: 10,
              modalOverlayOpeningRadius: 5,
              popperOptions: {
                modifiers: [{ name: 'offset', options: { offset: [0, 16] } }]
              },
              title: 'Step 1',
                text: 'In the first step, you will add product information, invoice number or order number.',
                attachTo: {
                  element: this.$refs.complaintStep[0],
                    on: 'bottom'
                },
                buttons: [
                  {
                        text: 'Back',
                        classes: 'shepherd-button-secondary',
                        action: this.tour.back
                    },
                    {
                        text: 'Next',
                        classes: 'shepherd-button-secondary',
                        action: this.tour.next
                    }
                ]
            });
            this.tour.addStep({
              modalOverlayOpeningPadding: 10,
              modalOverlayOpeningRadius: 5,
              popperOptions: {
                modifiers: [{ name: 'offset', options: { offset: [0, 16] } }]
              },
                title: 'Step 2',
                text: 'In the second step, the seller will need your personal information to handle your complaint',
                attachTo: {
                    element: this.$refs.complaintStep[1],
                    on: 'bottom'
                },
                buttons: [
                    {
                        text: 'Back',
                        classes: 'shepherd-button-secondary',
                        action: this.tour.back
                    },
                    {
                        text: 'Next',
                        classes: 'shepherd-button-secondary',
                        action: this.tour.next
                    }
                ]
            });
            this.tour.addStep({
              modalOverlayOpeningPadding: 10,
              modalOverlayOpeningRadius: 5,
              popperOptions: {
                modifiers: [{ name: 'offset', options: { offset: [0, 16] } }]
              },
                title: 'Step 3',
                text: 'In the third step, the seller will need information from you on where to ship product back after a failed/successful claim or where to send the money back when chargeback is selected',
                attachTo: {
                    element: this.$refs.complaintStep[2],
                    on: 'bottom'
                },
                buttons: [
                    {
                        text: 'Back',
                        classes: 'shepherd-button-secondary',
                        action: this.tour.back
                    },
                    {
                        text: 'Next',
                        classes: 'shepherd-button-secondary',
                        action: this.tour.next
                    }
                ]
            });
            this.tour.addStep({
              modalOverlayOpeningRadius: 5,
              popperOptions: {
                modifiers: [{ name: 'offset', options: { offset: [0, 4] } }]
              },
              text: 'We are currently in step one of the form, where you are able to complete the information needed for the claim. This can be considered the main application form, because here is the most important information for the seller is found here. This gives information about which product is in question.',
              attachTo: {
                  element: this.$refs.stepOneProduct[0],
                  on: 'left'
              },
              buttons: [
                  {
                      text: 'Back',
                      classes: 'shepherd-button-secondary',
                      action: this.tour.back
                  },
                  {
                      text: 'Next',
                      classes: 'shepherd-button-secondary',
                      action: this.tour.next
                  }
              ]
            });
            if(this.$store.state.portalSettings.fields.product.expectationInput.isShowed === true) {
              this.tour.addStep({
                modalOverlayOpeningPadding: 10,
                modalOverlayOpeningRadius: 5,
                popperOptions: {
                  modifiers: [{ name: 'offset', options: { offset: [0, 16] } }]
                },
                title: 'Expectations',
                text: `Here's where you get to choose your expectations for your complaint.
                Do you need a cash refund? Choose cash back.
                You want your product repaired? Choose item repair.
                It's very simnple!`,
                attachTo: {
                  element: this.$refs.productExpectations[0],
                  on: 'right'
                },
                buttons: [
                  {
                    text: 'Back',
                    classes: 'shepherd-button-secondary',
                    action: this.tour.back
                  },
                  {
                    text: 'Next',
                    classes: 'shepherd-button-secondary',
                    action: this.tour.next
                  },
                ]
              });
            }
            this.tour.addStep({
              modalOverlayOpeningPadding: 10,
              modalOverlayOpeningRadius: 5,
              popperOptions: {
                modifiers: [{ name: 'offset', options: { offset: [0, 16] } }]
              },
              title: 'Product name',
              text: 'In the product name field, just enter the name of the claimed product, e.g.: dresser',
              attachTo: {
                element: this.$refs.productName[0],
                on: 'left'
              },
              buttons: [
                  {
                    text: 'Back',
                    classes: 'shepherd-button-secondary',
                    action: this.tour.back
                  },
                  {
                    text: 'Next',
                    classes: 'shepherd-button-secondary',
                    action: this.tour.next
                  }
              ]
            });
            if(this.$store.state.portalSettings.fields.product.descriptionInput.isShowed === true) {
              this.tour.addStep({
                modalOverlayOpeningPadding: 10,
                modalOverlayOpeningRadius: 5,
                popperOptions: {
                  modifiers: [{ name: 'offset', options: { offset: [0, 16] } }]
                },
                  title: 'Description',
                  text: 'In the problem description, it is recommended to write what happened to the product, what is its defect or simply the reason for returning the product.',
                  attachTo: {
                      element: this.$refs.productDescription[0],
                      on: 'right'
                  },
                  buttons: [
                      {
                        text: 'Back',
                        classes: 'shepherd-button-secondary',
                        action: this.tour.back
                      },
                      {
                        text: 'Next',
                        classes: 'shepherd-button-secondary',
                        action: this.tour.next
                      }
                  ]
              });
            }
            if(this.$store.state.portalSettings.fields.product.invoiceNumberInput.isShowed === true) {
              this.tour.addStep({
                modalOverlayOpeningPadding: 10,
                modalOverlayOpeningRadius: 5,
                popperOptions: {
                  modifiers: [{ name: 'offset', options: { offset: [0, 16] } }]
                },
                  title: 'Invoice/Order number',
                  text: 'Fill in the order number or invoice number you got from the seller',
                  attachTo: {
                      element: this.$refs.productInvoice[0],
                      on: 'left'
                  },
                  buttons: [
                      {
                        text: 'Back',
                        classes: 'shepherd-button-secondary',
                        action: this.tour.back
                      },
                      {
                        text: 'Next',
                        classes: 'shepherd-button-secondary',
                        action: this.tour.next
                      }
                  ]
              });
            }
            if(this.$store.state.portalSettings.fields.product.imgInput.isShowed === true) {
              this.tour.addStep({
                modalOverlayOpeningPadding: 5,
                modalOverlayOpeningRadius: 5,
                popperOptions: {
                  modifiers: [{ name: 'offset', options: { offset: [0, 10] } }]
                },
                  title: 'Images',
                  text: 'Here you can send pictures of the damaged product, which is needed to check what happened to your product. ',
                  attachTo: {
                    element: this.$refs.productImages[0],
                    on: 'right'
                  },
                  buttons: [
                      {
                        text: 'Back',
                        classes: 'shepherd-button-secondary',
                        action: this.tour.back
                      },
                      {
                        text: 'Next',
                        classes: 'shepherd-button-secondary',
                        action: this.tour.next
                      }
                  ]
              });
            }
            if(this.$store.state.portalSettings.fields.product.imgInput.isShowed === true) {
              this.tour.addStep({
                modalOverlayOpeningRadius: 5,
                popperOptions: {
                  modifiers: [{ name: 'offset', options: { offset: [0, 8] } }]
                },
                  text: 'Here you can also send pictures of the damaged product, which is needed to check what happened to your product.',
                  attachTo: {
                      element: this.$refs.imgButton[0],
                      on: 'right'
                  },
                  buttons: [
                      {
                          text: 'Back',
                          classes: 'shepherd-button-secondary',
                          action: this.tour.back
                      },
                      {
                          text: 'Next',
                          classes: 'shepherd-button-secondary',
                          action: this.tour.next
                      }
                  ]
              });
            }
            if(this.$store.state.portalSettings.fields.product.pdfInput.isShowed === true) {
              this.tour.addStep({
                modalOverlayOpeningPadding: 5,
                modalOverlayOpeningRadius: 5,
                popperOptions: {
                  modifiers: [{ name: 'offset', options: { offset: [0, 10] } }]
                },
                title: 'PDF',
                  text: 'Here you can send a PDF file that includes the invoice for the product',
                  attachTo: {
                    element: this.$refs.productPDF[0],
                      on: 'right'
                  },
                  buttons: [
                    {
                      text: 'Back',
                          classes: 'shepherd-button-secondary',
                          action: this.tour.back
                      },
                      {
                        text: 'Next',
                          classes: 'shepherd-button-secondary',
                          action: this.tour.next
                      }
                  ]
              });
            }
            if(this.$store.state.portalSettings.fields.product.pdfInput.isShowed === true) {
              this.tour.addStep({
                modalOverlayOpeningRadius: 5,
                popperOptions: {
                  modifiers: [{ name: 'offset', options: { offset: [0, 8] } }]
                },
                  text: 'Here you can also send a PDF file that includes the invoice for the product',
                  attachTo: {
                    element: this.$refs.pdfButton[0],
                    on: 'right'
                  },
                  buttons: [
                    {
                      text: 'Back',
                      classes: 'shepherd-button-secondary',
                      action: this.tour.back
                    },
                    {
                      text: 'Next',
                      classes: 'shepherd-button-secondary',
                      action: this.tour.next
                    },
                  ]
              });
            }
            if(this.$store.state.portalSettings.fields.product.imgInput.isShowed === true) {
              this.tour.addStep({
                modalOverlayOpeningRadius: 5,
                popperOptions: {
                  modifiers: [{ name: 'offset', options: { offset: [0, 8] } }]
                },
                  title: 'Delete button',
                  text: 'If you have added too many photos or you just cancel the complaint, you can press this button. The form with this product will be removed.',
                  attachTo: {
                      element: this.$refs.delButton[0],
                      on: 'right'
                  },
                  buttons: [
                    {
                      text: 'Back',
                      classes: 'shepherd-button-secondary',
                      action: this.tour.back
                    },
                    {
                      text: 'Next',
                      classes: 'shepherd-button-secondary',
                      action: this.tour.next
                    }
                  ]
              });
            }
            this.tour.addStep({
              modalOverlayOpeningRadius: 5,
              popperOptions: {
                modifiers: [{ name: 'offset', options: { offset: [0, 6] } }]
              },
              title: 'Add another product',
              text: 'If you have several products to advertise, you can add another product here. Just fill out the new form as the previous one.',
              attachTo: {
                  element: this.$refs.addNewProduct,
                  on: 'right'
              },
              buttons: [
                {
                  text: 'Back',
                  classes: 'shepherd-button-secondary',
                  action: this.tour.back
                },
                {
                  text: 'Next',
                  classes: 'shepherd-button-secondary',
                  action: this.tour.next
                }
              ]
            });
            this.tour.addStep({
              modalOverlayOpeningRadius: 5,
              popperOptions: {
                modifiers: [{ name: 'offset', options: { offset: [0, 6] } }]
              },
              title: 'Next step',
              text: 'Have you filled in all the information? Then you can continue to the next step and give the seller more information!',
              attachTo: {
                element: this.$refs.firstStepNextStep,
                on: 'bottom'
              },
            buttons: [
              {
                text: 'Back',
                classes: 'shepherd-button-secondary',
                action: this.tour.back
              },
              {
                text: 'Next',
                classes: 'shepherd-button-secondary',
                action: async () => {
                  await this.nextStepWithoutVerification()
                  await this.prepareSecondStepTutorial()
                  this.tour.next()
                }
              },
            ],
            });
            this.tour.start()
            this.disableTour = this.tour.isActive() 
          },
          prepareSecondStepTutorial() {
              this.tour.addStep({
              modalOverlayOpeningPadding: 10,
              modalOverlayOpeningRadius: 5,
              popperOptions: {
                modifiers: [{ name: 'offset', options: { offset: [0, 16] } }]
              },
                title: 'Step 2',
                text: "We have passed step one, now we're in step two",
                attachTo: {
                  element: this.$refs.complaintStep[1],
                    on: 'bottom'
                },
                buttons: [
                    {
                      text: 'Back',
                        classes: 'shepherd-button-secondary',
                        action: this.tour.back,
                        disabled: true,
                    },
                    {
                      text: 'Next',
                        classes: 'shepherd-button-secondary',
                        action: this.tour.next
                    }
                ]
            });
               this.tour.addStep({
              modalOverlayOpeningPadding: 10,
              modalOverlayOpeningRadius: 5,
              popperOptions: {
                modifiers: [{ name: 'offset', options: { offset: [0, 16] } }]
              },
                text: 'Here are the fields to be filled in. There will be your personal information so that the seller knows who is making a complaint.',
                attachTo: {
                  element: this.$refs.stepSecondProduct,
                    on: 'right'
                },
                buttons: [
                  {
                    text: 'Back',
                        classes: 'shepherd-button-secondary',
                        action: this.tour.back
                    },
                    {
                      text: 'Next',
                        classes: 'shepherd-button-secondary',
                        action: this.tour.next
                    }
                ]
            });
            if(this.$store.state.portalSettings.fields.credentials.nameInput.isShowed === true) {
              this.tour.addStep({
              modalOverlayOpeningPadding: 10,
              modalOverlayOpeningRadius: 5,
              popperOptions: {
                modifiers: [{ name: 'offset', options: { offset: [0, 16] } }]
              },
                title: 'Name',
                text: 'Here you must enter your name and surname for the seller.',
                attachTo: {
                  element: this.$refs.stepSecondName,
                    on: 'right'
                },
                buttons: [
                  {
                    text: 'Back',
                        classes: 'shepherd-button-secondary',
                        action: this.tour.back
                    },
                    {
                      text: 'Next',
                        classes: 'shepherd-button-secondary',
                        action: this.tour.next
                    }
                ]
             });
            }
            if(this.$store.state.portalSettings.fields.credentials.emailInput.isShowed === true) {
              this.tour.addStep({
              modalOverlayOpeningPadding: 10,
              modalOverlayOpeningRadius: 5,
              popperOptions: {
                modifiers: [{ name: 'offset', options: { offset: [0, 16] } }]
              },
                title: 'E-mail',
                text: 'Here you must enter your e-mail address for the seller.',
                attachTo: {
                  element: this.$refs.stepSecondEmail,
                    on: 'right'
                },
                buttons: [
                  {
                    text: 'Back',
                        classes: 'shepherd-button-secondary',
                        action: this.tour.back
                    },
                    {
                      text: 'Next',
                        classes: 'shepherd-button-secondary',
                        action: this.tour.next
                    }
                ]
             });
            }
            if(this.$store.state.portalSettings.fields.credentials.phoneInput.isShowed === true) {
              this.tour.addStep({
              modalOverlayOpeningPadding: 10,
              modalOverlayOpeningRadius: 5,
              popperOptions: {
                modifiers: [{ name: 'offset', options: { offset: [0, 16] } }]
              },
                title: 'Phone',
                text: 'Here you must enter your phone number for the seller.',
                attachTo: {
                  element: this.$refs.stepSecondPhone,
                    on: 'right'
                },
                buttons: [
                  {
                    text: 'Back',
                        classes: 'shepherd-button-secondary',
                        action: this.tour.back
                    },
                    {
                      text: 'Next',
                        classes: 'shepherd-button-secondary',
                        action: this.tour.next
                    }
                ]
             });
            }
            if(this.$store.state.portalSettings.fields.credentials.countryInput.isShowed === true) {
              this.tour.addStep({
              modalOverlayOpeningPadding: 10,
              modalOverlayOpeningRadius: 5,
              popperOptions: {
                modifiers: [{ name: 'offset', options: { offset: [0, 16] } }]
              },
                title: 'Country',
                text: 'Here you must enter your country for the seller.',
                attachTo: {
                  element: this.$refs.stepSecondCountry,
                    on: 'right'
                },
                buttons: [
                  {
                    text: 'Back',
                        classes: 'shepherd-button-secondary',
                        action: this.tour.back
                    },
                    {
                      text: 'Next',
                        classes: 'shepherd-button-secondary',
                        action: this.tour.next
                    }
                ]
             });
            }
            if(this.$store.state.portalSettings.fields.credentials.cityInput.isShowed === true) {
              this.tour.addStep({
              modalOverlayOpeningPadding: 10,
              modalOverlayOpeningRadius: 5,
              popperOptions: {
                modifiers: [{ name: 'offset', options: { offset: [0, 16] } }]
              },
                title: 'City',
                text: 'Here you must enter your city for the seller.',
                attachTo: {
                  element: this.$refs.stepSecondCity,
                    on: 'right'
                },
                buttons: [
                  {
                    text: 'Back',
                        classes: 'shepherd-button-secondary',
                        action: this.tour.back
                    },
                    {
                      text: 'Next',
                        classes: 'shepherd-button-secondary',
                        action: this.tour.next
                    }
                ]
             });
            }
            if(this.$store.state.portalSettings.fields.credentials.postCodeInput.isShowed === true) {
              this.tour.addStep({
              modalOverlayOpeningPadding: 10,
              modalOverlayOpeningRadius: 5,
              popperOptions: {
                modifiers: [{ name: 'offset', options: { offset: [0, 16] } }]
              },
                title: 'ZIP / Postal code',
                text: 'Here you must enter your ZIP / Postal code for the seller.',
                attachTo: {
                  element: this.$refs.stepSecondPostcode,
                    on: 'right'
                },
                buttons: [
                  {
                    text: 'Back',
                        classes: 'shepherd-button-secondary',
                        action: this.tour.back
                    },
                    {
                      text: 'Next',
                        classes: 'shepherd-button-secondary',
                        action: this.tour.next
                    }
                ]
             });
            }
            if(this.$store.state.portalSettings.fields.credentials.streetAddressInput.isShowed === true) {
              this.tour.addStep({
              modalOverlayOpeningPadding: 10,
              modalOverlayOpeningRadius: 5,
              popperOptions: {
                modifiers: [{ name: 'offset', options: { offset: [0, 16] } }]
              },
                title: 'Street address',
                text: 'Here you must enter your street address for the seller.',
                attachTo: {
                  element: this.$refs.stepSecondStreetAdress,
                    on: 'right'
                },
                buttons: [
                  {
                    text: 'Back',
                        classes: 'shepherd-button-secondary',
                        action: this.tour.back
                    },
                    {
                      text: 'Next',
                        classes: 'shepherd-button-secondary',
                        action: this.tour.next
                    }
                ]
             });
            }
            this.tour.addStep({
              modalOverlayOpeningRadius: 5,
              popperOptions: {
                modifiers: [{ name: 'offset', options: { offset: [0, 8] } }]
              },
              text: 'If you want to change any information in step one, you can click this button and automatically go back to the beginning of the form.',
              attachTo: {
                element: this.$refs.stepSecondpreviousStep,
                on: 'right'
              },
              buttons: [
                {
                  text: 'Back',
                  classes: 'shepherd-button-secondary',
                  action: this.tour.back
                },
                {
                  text: 'Next',
                  classes: 'shepherd-button-secondary',
                  action: this.tour.next
                }
              ]
            });

            this.tour.addStep({
              modalOverlayOpeningRadius: 5,
              popperOptions: {
                modifiers: [{ name: 'offset', options: { offset: [0, 8] } }]
              },
              text: 'All the information filled in? Click the button and go to the next step!',
              attachTo: {
                element: this.$refs.stepSecondNextStep,
                  on: 'right'
              },
              buttons: [
                {
                  text: 'Back',
                  classes: 'shepherd-button-secondary',
                  action: this.tour.back
                },
                {
                  text: 'Next',
                  classes: 'shepherd-button-secondary',
                  action: async () => {
                    await this.nextStepWithoutVerification()
                    await this.prepareThirdStepTutorial()
                    this.tour.next()
                  },
                }
              ]
             });
          },
          prepareThirdStepTutorial() {
            this.tour.addStep({
              modalOverlayOpeningPadding: 10,
              modalOverlayOpeningRadius: 5,
              popperOptions: {
                modifiers: [{ name: 'offset', options: { offset: [0, 16] } }]
              },
              title: 'Step 3',
              text: 'In the third step, you fill in for the seller the address to which the returned product should be shipped, or the account number to which the money from the return should go.',
              attachTo: {
                element: this.$refs.complaintStep[2],
                on: 'bottom'
              },
              buttons: [
                {
                  text: 'Back',
                  classes: 'shepherd-button-secondary',
                  action: this.tour.back,
                  disabled: true,
                },
                {
                  text: 'Next',
                  classes: 'shepherd-button-secondary',
                  action: this.tour.next
                }
              ]
            });
            this.tour.addStep({
              modalOverlayOpeningPadding: 10,
              modalOverlayOpeningRadius: 5,
              popperOptions: {
                modifiers: [{ name: 'offset', options: { offset: [0, 16] } }]
              },
              text: 'In the highlighted area, fill in the necessary information and move on',
              attachTo: {
                element: this.$refs.thirdStepForm,
                  on: 'right'
              },
                buttons: [
                  {
                    text: 'Back',
                    classes: 'shepherd-button-secondary',
                    action: this.tour.back
                  },
                  {
                    text: 'Next',
                    classes: 'shepherd-button-secondary',
                    action: async () => {
                      await this.prepareEndingTutorial()
                      this.tour.next()
                    },
                  }
                ]
            });
          },
          prepareEndingTutorial() {
            this.tour.addStep({
              title: 'Thank you!',
              text: 'Thank you for finishing the tutorial. When you click the button, it will take you to the beginning and you can fill out all the necessary information in the actual form.',
              buttons: [
                {
                  text: 'Back',
                  classes: 'shepherd-button-secondary',
                  action: this.tour.back
                },
                {
                  text: 'Next',
                  classes: 'shepherd-button-secondary',
                  action: () => {
                    this.tour.complete()
                    if(this.tour.complete) {
                      this.disableTour = false;
                    }
                    if(this.formStep === 1) {
                      this.$store.commit('setNotification',{
                        show: true,
                        head: "KJSDFLKSJSDLK",
                        subheader: ``,
                        success: false
                      });
                    } else {
                      this.steps[this.formStep-1].status = "";
                      this.formStep -= 2;
                      this.steps[this.formStep-1].status = "current";
                    }
                  }
                }
              ]
            });
          },
          addMessage(message, unix){
            message.date = {
              _seconds: unix,
            }
            this.complaintData.chat.messages.push(message)
            // console.log(this.complaintData.chat.messages)
          },
          async downloadComplaintData(){
            // this.$store.commit('setGlobalLoader',{
            //   show: true,
            //   head: "Loading your complaint...",
            //   subheader: `Don't close this tab.`,
            //   success: false
            // });
            this.checkComplaint = true
            const result = await axios.post("https://europe-central2-complaia.cloudfunctions.net/api/download/getComplaintData", {
              portalLink: this.$route.query.portal,
              complaint: this.$route.query.complaint,
            })
            if(result.data.status === 'ok'){
              // console.log(result.data)
              this.complaintData = {
                data: result.data.data,
                type: result.data.type,
                company: result.data.company,
                attendant: result.data.attendant,
                chat: result.data.chat
              }
            }
            if(result.data.status === 'notExist'){
              // console.log(result.data)
              this.complaintData = {
                data: null,
                type: 'notExist',
                company: result.data.company,
              }
            }
          },
          checkExpectations() {
            let moneyReturn = 0;
            let noInformation = 0;
            for (let i = 0; i < this.complaintTemplate.products.length; i++) {
              if(this.complaintTemplate.products[i].expectation.isCheckout) {
                moneyReturn++
              }
              if(this.complaintTemplate.products[i].expectation.name === "No information") {
                noInformation++
              }
            }

            if (noInformation > 0) {
              this.steps[2].name = "Chargeback / Delivery"
            } else if(moneyReturn === this.complaintTemplate.products.length) {
              this.steps[2].name = "Chargeback"
            } else if (moneyReturn === 0) {
              this.steps[2].name = "Delivery"
            } else {
              this.steps[2].name = "Chargeback / Delivery"
            }
          },
          checkErrors(step) {
            this.errorProduct = null
            // Step 1 START
            if (step === 1) {
                for(let i = 0; i < this.errorsStep1.length; i++) {

                // Check product name
                if(this.$store.state.portalSettings.fields.product.productNameInput.isShowed) {
                  if(this.$store.state.portalSettings.fields.product.productNameInput.isRequired) {
                    if(this.complaintTemplate.products[i].name.length < 1) {
                      this.errorsStep1Text[i].productName = "Product name cannot be empty!";
                      this.errorsStep1[i].productName = true;
                    } else {
                      this.errorsStep1Text[i].productName = "";
                      this.errorsStep1[i].productName = false;
                    }
                  }
                }

                // Check description
                if(this.$store.state.portalSettings.fields.product.descriptionInput.isShowed) {
                  if(this.$store.state.portalSettings.fields.product.descriptionInput.isRequired) {
                    if(this.complaintTemplate.products[i].problemDescription.length < 1) {
                      this.errorsStep1Text[i].problemDescription = "Problem description cannot be empty!";
                      this.errorsStep1[i].problemDescription = true;
                    } else {
                      this.errorsStep1Text[i].problemDescription = "";
                      this.errorsStep1[i].problemDescription = false;
                    }
                  }
                }

                // Check invoiceNumber
                if(this.$store.state.portalSettings.fields.product.invoiceNumberInput.isShowed) {
                  if(this.$store.state.portalSettings.fields.product.invoiceNumberInput.isRequired) {
                    if(this.complaintTemplate.products[i].invoiceNumber.length < 1) {
                      this.errorsStep1Text[i].invoiceNumber = "Invoice number cannot be empty!";
                      this.errorsStep1[i].invoiceNumber = true;
                    } else {
                      this.errorsStep1Text[i].invoiceNumber = "";
                      this.errorsStep1[i].invoiceNumber = false;
                    }
                  }
                }

                // Check img
                if(this.$store.state.portalSettings.fields.product.imgInput.isShowed) {
                  if(this.$store.state.portalSettings.fields.product.imgInput.isRequired) {
                    if(this.imgHolder[this.complaintTemplate.products[i].id].length < 1) {
                      this.errorsStep1Text[i].img = "You must add at least one image!";
                      this.errorsStep1[i].img = true;
                    } else {
                      this.errorsStep1Text[i].img = "";
                      this.errorsStep1[i].img = false;
                    }
                  }
                }

                // Check pdf
                if(this.$store.state.portalSettings.fields.product.pdfInput.isShowed) {
                  if(this.$store.state.portalSettings.fields.product.pdfInput.isRequired) {
                    if(this.pdfHolder[this.complaintTemplate.products[i].id].length < 1) {
                      this.errorsStep1Text[i].pdf = "You need to add a PDF file!";
                      this.errorsStep1[i].pdf = true;
                    } else {
                      this.errorsStep1Text[i].pdf = "";
                      this.errorsStep1[i].pdf = false;
                    }
                  }
                }
              }
              let errorsStep1 = 0
              
              // Find first product with error and expand all products
              for(let i = 0; i < this.errorsStep1.length; i++) {
                if ((this.errorsStep1[i].productName || this.errorsStep1[i].problemDescription || this.errorsStep1[i].invoiceNumber || this.errorsStep1[i].img || this.errorsStep1[i].pdf) && this.errorProduct === null) {
                  errorsStep1++
                  this.errorProduct = i
                  if(!this.complaintTemplate.products[i].expanded) {
                    this.expand(i, false, true)
                  }
                } else if (this.errorsStep1[i].productName && this.errorsStep1[i].problemDescription && this.errorsStep1[i].invoiceNumber) {
                  errorsStep1++
                  if(!this.complaintTemplate.products[i].expanded) {
                    this.expand(i, false, true)
                  }
                }
              }
              
              // Next step if all ok
              if (errorsStep1 === 0) {
                this.nextStepWithoutVerification()
              } else {
                this.$store.commit('setNotification',{
                  show: true,
                  head: "Please enter the required fields to continue.",
                  subheader: ``,
                  success: false
                  }); 
                  const elmntToView = document.getElementById(`product${this.errorProduct}`);
                  elmntToView.scrollIntoView({block: "center", behavior: "smooth"}); 
              }
            }
            // Step 1 END

            // Step 2 START
            if (step === 2) {
              let errorsStep2 = 0
                // Check name
                if(this.$store.state.portalSettings.fields.credentials.nameInput.isShowed) {
                  if(this.$store.state.portalSettings.fields.credentials.nameInput.isRequired) {
                    if(this.complaintTemplate.clientCredentials.name.length < 1) {
                    this.errorsStep2Text[0].name = "Name cannot be empty!";
                    this.errorsStep2[0].name = true;
                    errorsStep2++
                    } else {
                      this.errorsStep2Text[0].name = "";
                      this.errorsStep2[0].name = false;
                    }
                  }
                }
                
                // Check email
                if(this.$store.state.portalSettings.fields.credentials.emailInput.isShowed) {
                  if(this.$store.state.portalSettings.fields.credentials.emailInput.isRequired) {
                    if(this.complaintTemplate.clientCredentials.email.length < 1) {
                      this.errorsStep2Text[0].email = "E-mail cannot be empty!";
                      this.errorsStep2[0].email = true;
                      errorsStep2++
                    } else if (!/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(this.complaintTemplate.clientCredentials.email)) {
                      this.errorsStep2Text[0].email = "E-mail address is invalid. Sample e-mail address: complaia@gmail.com";
                      this.errorsStep2[0].email = true;
                      errorsStep2++
                    } else {
                      this.errorsStep2Text[0].email = "";
                      this.errorsStep2[0].email = false;
                    }
                  }
                }
                
                // Check street address
                if(this.$store.state.portalSettings.fields.credentials.streetAddressInput.isShowed) {
                  if(this.$store.state.portalSettings.fields.credentials.streetAddressInput.isRequired) {
                    if(this.complaintTemplate.clientCredentials.streetAddress.length < 1) {
                      this.errorsStep2Text[0].streetAddress = "Street address cannot be empty!";
                      this.errorsStep2[0].streetAddress = true;
                      errorsStep2++
                    } else {
                      this.errorsStep2Text[0].streetAddress = "";
                      this.errorsStep2[0].streetAddress = false;
                    }
                  }
                }
                
                // Check city
                if(this.$store.state.portalSettings.fields.credentials.cityInput.isShowed) {
                  if(this.$store.state.portalSettings.fields.credentials.cityInput.isRequired) {
                    if(this.complaintTemplate.clientCredentials.city.length < 1) {
                      this.errorsStep2Text[0].city = "City field cannot be empty!";
                      this.errorsStep2[0].city = true;
                      errorsStep2++
                    } else {
                      this.errorsStep2Text[0].city = "";
                      this.errorsStep2[0].city = false;
                    }
                  }
                }

                // Check phone
                if(this.$store.state.portalSettings.fields.credentials.phoneInput.isShowed) {
                  if(this.$store.state.portalSettings.fields.credentials.phoneInput.isRequired) {
                    if(this.complaintTemplate.clientCredentials.phone.length < 1) {
                      this.errorsStep2Text[0].phone = "Phone field cannot be empty!";
                      this.errorsStep2[0].phone = true;
                      errorsStep2++
                    } else {
                      this.errorsStep2Text[0].phone = "";
                      this.errorsStep2[0].phone = false;
                    }
                  }
                }
                
                // Check postCode
                if(this.$store.state.portalSettings.fields.credentials.postCodeInput.isShowed) {
                  if(this.$store.state.portalSettings.fields.credentials.postCodeInput.isRequired) {
                    if(this.complaintTemplate.clientCredentials.postCode.length < 1) {
                      this.errorsStep2Text[0].postCode = "Postal code cannot be empty!";
                      this.errorsStep2[0].postCode = true;
                      errorsStep2++
                    } else {
                      this.errorsStep2Text[0].postCode = "";
                      this.errorsStep2[0].postCode = false;
                    }
                  }
                }
                
                // Check country
                if(this.$store.state.portalSettings.fields.credentials.countryInput.isShowed) {
                  if(this.$store.state.portalSettings.fields.credentials.countryInput.isRequired) {
                    if(this.complaintTemplate.clientCredentials.country.length < 1) {
                      this.errorsStep2Text[0].country = "Country field cannot be empty!";
                      this.errorsStep2[0].country = true;
                      errorsStep2++
                    } else {
                      this.errorsStep2Text[0].country = "";
                      this.errorsStep2[0].country = false;
                    }
                  }
                }
                
                // Next step if all ok
                if (errorsStep2 === 0) {
                if(this.checkSteps.booleanSteps) {
                  this.sendToBuffer()
                } else {
                  this.nextStepWithoutVerification()
                }
                } else {
                this.$store.commit('setNotification',{
                  show: true,
                  head: "Please enter the required fields to continue.",
                  subheader: ``,
                  success: false
                  }); 
                }
              }
            // Step 2 END

            // Step 3 START
            if(step === 3) {
              let errorsStep3 = 0

              // Check country
              if(this.$store.state.portalSettings.fields.deliveryChargeback.countryInput.isShowed && this.steps[2].name !== "Chargeback") {
                if(this.$store.state.portalSettings.fields.deliveryChargeback.countryInput.isRequired) {
                  if(this.complaintTemplate.delivery.country < 1) {
                  this.errorsStep3Text[0].country = "Country field cannot be empty!";
                  this.errorsStep3[0].country = true;
                  errorsStep3++
                  } else {
                    this.errorsStep3Text[0].country = "";
                    this.errorsStep3[0].country = false;
                  }
                }
              }
              
              // Check street address
              if(this.$store.state.portalSettings.fields.deliveryChargeback.streetAddressInput.isShowed && this.steps[2].name !== "Chargeback") {
                if(this.$store.state.portalSettings.fields.deliveryChargeback.streetAddressInput.isRequired) {
                  if(this.complaintTemplate.delivery.streetAddress < 1) {
                    this.errorsStep3Text[0].streetAddress = "Street address cannot be empty!";
                    this.errorsStep3[0].streetAddress = true;
                    errorsStep3++
                  } else {
                    this.errorsStep3Text[0].streetAddress = "";
                    this.errorsStep3[0].streetAddress = false;
                  }
                }
              }
              
              // Check city
              if(this.$store.state.portalSettings.fields.deliveryChargeback.cityInput.isShowed && this.steps[2].name !== "Chargeback") {
                if(this.$store.state.portalSettings.fields.deliveryChargeback.cityInput.isRequired) {
                  if(this.complaintTemplate.delivery.city < 1) {
                    this.errorsStep3Text[0].city = "City field cannot be empty!";
                    this.errorsStep3[0].city = true;
                    errorsStep3++
                  } else {
                    this.errorsStep3Text[0].city = "";
                    this.errorsStep3[0].city = false;
                  }
                }
              }
              
              // Check postCode
              if(this.$store.state.portalSettings.fields.deliveryChargeback.postCodeInput.isShowed && this.steps[2].name !== "Chargeback") {
                if(this.$store.state.portalSettings.fields.deliveryChargeback.postCodeInput.isRequired) {
                  if(this.complaintTemplate.delivery.postCode < 1) {
                    this.errorsStep3Text[0].postCode = "Postal code cannot be empty!";
                    this.errorsStep3[0].postCode = true;
                    errorsStep3++
                  } else {
                    this.errorsStep3Text[0].postCode = "";
                    this.errorsStep3[0].postCode = false;
                  }
                }
              }
              // Check account number
              if(this.$store.state.portalSettings.fields.deliveryChargeback.bankAccountNumberInput.isShowed) {
                if(this.$store.state.portalSettings.fields.deliveryChargeback.bankAccountNumberInput.isRequired) {
                  if(this.complaintTemplate.clientCredentials.accountNumber < 1 && (this.steps[2].name === "Chargeback / Delivery" || this.steps[2].name === "Chargeback")) {
                    this.errorsStep3Text[0].accountNumber = "Bank account number cannot be empty!";
                    this.errorsStep3[0].accountNumber = true;
                    errorsStep3++
                  } else {
                    this.errorsStep3Text[0].accountNumber = "";
                    this.errorsStep3[0].accountNumber = false;
                  }
                }
              }

              // if all ok send to buffer
              if (errorsStep3 === 0) {
                if(this.steps[2].name === 'Delivery') {
                  this.complaintTemplate.clientCredentials.accountNumber = ""
                }
                this.sendToBuffer()
                } else {
                this.$store.commit('setNotification',{
                  show: true,
                  head: "Please enter the required fields to continue.",
                  subheader: ``,
                  success: false
                  }); 
                }
            }
            // Step 3 END
          },
          async sendToBuffer() {
              this.$store.commit('setGlobalLoader',{
                show: true,
                head: "Sending your complaint...",
                subheader: `Do not close this tab.`,
                success: false
              });
              const getSystemId = await axios.post("https://europe-central2-complaia.cloudfunctions.net/api/get/systemId", {
              portalLink: this.$route.query.portal,
              })
              let products = JSON.parse(JSON.stringify(this.complaintTemplate.products))
              
              for(let i = 0; i < this.complaintTemplate.products.length; i++) {
                delete products[i].expanded
                products[i].externalId = {
                  type: this.$store.state.portalSettings.fields.product.invoiceNumberInput.fieldName,
                  value: products[i].invoiceNumber
                }
                delete products[i].invoiceNumber
                // Send pdf file
                if(this.pdfHolder[this.complaintTemplate.products[i].id].length > 0) {
                  const storageRef = storage.ref();
                  const filename = "pdf_" + this.filenameGenerator(10, getSystemId.data.systemId);
                  const originalName = this.pdfHolder[this.complaintTemplate.products[i].id][0].file.name
                  const fileSize = this.pdfHolder[this.complaintTemplate.products[i].id][0].file.size
                  const type = this.pdfHolder[this.complaintTemplate.products[i].id][0].file.type
                  const pdfPath = `systems/${getSystemId.data.systemId}/complaintsBuffer/${this.complaintTemplate.formId}/cases/${this.complaintTemplate.products[i].id}/pdf/${filename}`
                  const pdfRef = storageRef.child(pdfPath);
                  await pdfRef.put(this.pdfHolder[this.complaintTemplate.products[i].id][0].file);
                  const fileUrl = await pdfRef.getDownloadURL();
                  products[i].files.pdfs.push({
                    name: filename,
                    originalName: originalName,
                    type: type,
                    size: fileSize,
                    url: fileUrl,
                    path: pdfPath
                  })
                } else {
                  products[i].files.pdfs = []
                }
                // Send images
                if(this.imgHolder[this.complaintTemplate.products[i].id].length > 0) {
                  for(let y = 0; y < this.imgHolder[this.complaintTemplate.products[i].id].length; y++) {
                    const storageRef = storage.ref();
                    const filename = "img_" + this.filenameGenerator(10, getSystemId.data.systemId);
                    const originalName = this.imgHolder[this.complaintTemplate.products[i].id][y].file.name
                    const fileSize = this.imgHolder[this.complaintTemplate.products[i].id][y].file.size
                    const type = this.imgHolder[this.complaintTemplate.products[i].id][y].file.type
                    const imagePath = `systems/${getSystemId.data.systemId}/complaintsBuffer/${this.complaintTemplate.formId}/cases/${this.complaintTemplate.products[i].id}/images/${filename}`
                    const imageRef = storageRef.child(imagePath);
                    await imageRef.put(this.imgHolder[this.complaintTemplate.products[i].id][y].file);
                    const fileUrl = await imageRef.getDownloadURL();
                    products[i].files.images.push({
                      name: filename,
                      originalName: originalName,
                      type: type,
                      size: fileSize,
                      url: fileUrl,
                      path: imagePath
                    })
                  }
                } 
              }
              // Prepare form data to db
              let formData = {
                formId: this.complaintTemplate.formId,
                delivery: this.complaintTemplate.delivery,
                products: products,
                clientCredentials: {
                  accountNumber: this.complaintTemplate.clientCredentials.accountNumber,
                  city: this.complaintTemplate.clientCredentials.city,
                  country: this.complaintTemplate.clientCredentials.country,
                  email: this.complaintTemplate.clientCredentials.email,
                  name: this.complaintTemplate.clientCredentials.name,
                  phone: this.complaintTemplate.clientCredentials.phone,
                  postCode: this.complaintTemplate.clientCredentials.postCode,
                  streetAddress: this.complaintTemplate.clientCredentials.streetAddress
                }
              }
              // Send data
              const result = await axios.post("https://europe-central2-complaia.cloudfunctions.net/api/send/buffer", {
                portalLink: this.$route.query.portal,
                formData: formData
              })
              if(result.request.status === 200) {
                this.$router.push(`/?portal=${this.$route.query.portal}&complaint=${this.complaintTemplate.formId}`)
              } else {
                this.$store.commit('setNotification',{
                  show: true,
                  head: `${result.request.status}`,
                  subheader: `${result.request.statusText}`,
                  success: false
                });
              }
            
          },
          filenameGenerator(length, systemId)
            {
                let result           = `${systemId}_bufferCaseFile_`;
                let characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
                let charactersLength = characters.length;
                for ( let i = 0; i < length; i++ ) {
                    result += characters.charAt(Math.floor(Math.random() * 
                    charactersLength));
                }
                return result;
            },
          nextStepWithoutVerification()
          {
            if(this.formStep === 4) {
              this.$store.commit('setNotification',{
                show: true,
                head: "KJSDFLKSJSDLK",
                subheader: ``,
                success: false
              });
            } else {
              this.steps[this.formStep-1].status = "complete";
              this.formStep++;
              this.steps[this.formStep-1].status = "current";
            }
          },
          previousStep()
          {
            if(this.formStep === 1) {
              this.$store.commit('setNotification',{
                show: true,
                head: "KJSDFLKSJSDLK",
                subheader: ``,
                success: false
              });
            } else {
              this.steps[this.formStep-1].status = "";
              this.formStep--;
              this.steps[this.formStep-1].status = "current";
            }
          },
          // Download custom portalSettings
          async downloadPortalSettings() {
            this.expectations = []
            const result = await axios.post("https://europe-central2-complaia.cloudfunctions.net/api/download/formData", {
              portalLink: this.$route.query.portal,
            })
            this.$store.state.portalSettings = result.data.portalSettings
            if(this.$store.state.portalSettings.htmlMeta.title.length > 0) {
              document.title = this.$store.state.portalSettings.htmlMeta.title
            }
            this.$store.state.portalSettings.appearance.themeColor = (this.$store.state.portalSettings.appearance.themeColor).toLowerCase()
            this.expectations = this.$store.state.portalSettings.fields.product.expectationInput.options.values
            this.imgQty = this.$store.state.portalSettings.fields.product.imgInput.imgQtyLimit
            this.perImgSizeMB = this.$store.state.portalSettings.fields.product.imgInput.imgSizeLimit
            this.loading = false
            // this.$store.commit("resetGlobalLoader");
          },
          addNewProduct()
            {
              const productTemplate = {
                  id: generateApiKey({ method: 'string', pool: "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789", length: 16 }),
                  name: "",
                  invoiceNumber: "",
                  problemDescription: "",
                  expectation: this.$store.state.portalSettings.fields.product.expectationInput.options.default,
                  files: {
                  images: [],
                  pdfs: [],
                  videos: [],
                  other: []
                  },
                  expanded: true,
              }
              this.errorsStep1.push({
                productName: false,
                problemDescription: false,
                invoiceNumber: false,
                img: false,
                pdf: false,
              })
              this.errorsStep1Text.push({
                productName: "",
                problemDescription: "",
                invoiceNumber: "",
                img: "",
                pdf: "",
              })
              this.imgHolder[`${productTemplate.id}`] = [];
              this.pdfHolder[`${productTemplate.id}`] = [];
              this.complaintTemplate.products.push(productTemplate);
              if (this.complaintTemplate.products.length > 1) {
                this.expand(this.complaintTemplate.products.length-2, true)
              }
              this.checkExpectations()

            },
          addToPDFHolder(event,holderId)
            {
              this.pdfHolder[holderId] = [];
              if(event.target.files.length === 0) return;
              const file = event.target.files[0];
              const sizeInMb = file.size / 1024 / 1024;
              if(sizeInMb <= this.perPDFSizeMB)
              {
                this.pdfHolder[holderId].push({file: file});
                this.$store.commit('setNotification',{
                  show: true,
                  head: "PDF file has been added!",
                  subheader: ``,
                  success: true
                }); 
              }
              else
              {
                  this.$store.commit('setNotification',{
                    show: true,
                    head: "Cannot add this PDF!",
                    subheader: `File size is more than ${this.perPDFSizeMB} MB.`,
                    success: false
                  });                
              }
            },
            addToImgHolder(event,holderId)
            {
              if(event.target.files.length === 0) return;
              const file = event.target.files[0];
              const sizeInMb = file.size / 1024 / 1024;
              if(sizeInMb <= this.perImgSizeMB)
              {
                let fileAlreadyAdded = false;
                for(let i=0;i<this.imgHolder[holderId].length;i++)
                {
                  if(this.imgHolder[holderId][i].file.name === file.name)
                  {
                    fileAlreadyAdded = true;
                  }
                }
                if(!fileAlreadyAdded)
                {
                  const reader = new FileReader();
                  reader.onload = (event) => {
                      const content = event.target.result;
                      this.imgHolder[holderId].push({file: file, dataURL: content});
                      this.$store.commit('setNotification',{
                        show: true,
                        head: "Image has been added!",
                        subheader: ``,
                        success: true
                      }); 
                  };
                  reader.readAsDataURL(file);
                }
                else
                {
                  this.$store.commit('setNotification',{
                    show: true,
                    head: "Cannot add this image!",
                    subheader: `List contains file with same name.`,
                    success: false
                  });                  
                }
              }
              else
              {
                this.$store.commit('setNotification',{
                  show: true,
                  head: "Cannot add this image!",
                  subheader: `File size is more than ${this.perImgSizeMB} MB.`,
                  success: false
                });
              }
            },
            triggerPDFUploaderClick(uploaderRefAsText)
            {
              this.$refs[uploaderRefAsText][0].click();
            },
            triggerImgUploaderClick(uploaderRefAsText,left)
            {
              if(left === 0) return;
              this.$refs[uploaderRefAsText][0].click();
            },
            imgDelete(ref,flIndx)
            {
              ref.splice(flIndx,1);
              this.$store.commit('setNotification',{
                show: true,
                head: "Image has been deleted!",
                subheader: ``,
                success: true
              });
            },
            deleteProduct(index)
            {
              if(this.complaintTemplate.products.length === 1) {
                this.$store.commit('setNotification',{
                show: true,
                head: "You cannot delete the last product!",
                subheader: ``,
                success: false
                }); 
              } else {
                this.complaintTemplate.products.splice(index,1);
                this.errorsStep1.splice(index,1);
                this.errorsStep1Text.splice(index,1);
                this.errorsStep2.splice(index,1);
                this.errorsStep2Text.splice(index,1);
                this.errorsStep3.splice(index,1);
                this.errorsStep3Text.splice(index,1);
                this.checkExpectations()
                this.$store.commit('setNotification',{
                show: true,
                head: "Product has been deleted!",
                subheader: ``,
                success: true
                }); 
              }
              
            },
            // Expand products
            expand(button, fromAdd, fromErrors) {
              
              if(this.complaintTemplate.products.length >= 1) {
                if(!fromAdd) {
                    this.complaintTemplate.products[button].expanded = !this.complaintTemplate.products[button].expanded
                    document.querySelectorAll('.goals-box')[button].classList.toggle('expand');
                    document.querySelectorAll('.expand-icon')[button].classList.toggle('rotated');
                    if (this.complaintTemplate.products[button].expanded && !fromErrors) {
                      setTimeout(() => {
                      const elmntToView = document.getElementById(`product${button}`);
                      elmntToView.scrollIntoView({block: "center", behavior: "smooth"}); 
                    }, 300)
                  }
                } else if (fromAdd) {
                  if (this.complaintTemplate.products[button].expanded) {
                    this.complaintTemplate.products[button].expanded = !this.complaintTemplate.products[button].expanded
                    document.querySelectorAll('.goals-box')[button].classList.toggle('expand');
                    document.querySelectorAll('.expand-icon')[button].classList.toggle('rotated');
                  if(!this.complaintTemplate.products[button+1].expanded) {
                      setTimeout(()=>
                      {
                        const elmntToView = document.getElementById(`product${button+1}`);
                        elmntToView.scrollIntoView({block: "center", behavior: "smooth"}); 
                      },450)
                    }
                  } else {
                    setTimeout(()=>
                      {
                        const elmntToView = document.getElementById(`product${button+1}`);
                        elmntToView.scrollIntoView({block: "center", behavior: "smooth"}); 
                      },50)
                  }
                }
              }
            },
        },
        async created()
        {
          this.$store.commit('setGlobalLoader',{
            show: true,
          head: "Loading complaint site...",
          subheader: `Do not close this tab.`,
          success: false
          });
          if(this.$route.query.complaint !== undefined) {
            this.showAlert = false
          } else {
            this.showAlert = true
          }
          if(this.$route.query.portal === undefined && this.$route.query.complaint === undefined || this.$route.query.portal === undefined || this.$route.query.portal === null || this.$route.query.portal.length === 0) {
            window.location.href = 'https://complaia.com/'
          }
          await this.downloadPortalSettings();
          console.log(this.$store.state.portalSettings.fields.deliveryChargeback);
          if(this.$route.query.complaint === undefined){
           const productTemplate = {
                  id: generateApiKey({ method: 'string', pool: "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789", length: 16 }),
                  name: "",
                  invoiceNumber: "",
                  problemDescription: "",
                  expectation: this.$store.state.portalSettings.fields.product.expectationInput.options.default,
                  files: {
                  images: [],
                  pdfs: [],
                  videos: [],
                  other: []
                  },
                  expanded: true,
              }
              // Errors
              this.errorsStep1.push({
                productName: false,
                problemDescription: false,
                invoiceNumber: false,
                img: false,
                pdf: false,
              })
              this.errorsStep1Text.push({
                productName: "",
                problemDescription: "",
                invoiceNumber: "",
                img: "",
                pdf: "",
              })
              this.errorsStep2.push({
                name: false,
                email: false,
                streetAddress: false,
                city: false,
                phone: false,
                postCode: false,
                country: false,
              })
              this.errorsStep2Text.push({
                name: "",
                email: "",
                streetAddress: "",
                city: "",
                phone: "",
                postCode: "",
                country: "",
              })
              this.errorsStep3.push({
                streetAddress: false,
                city: false,
                postCode: false,
                country: false,
                accountNumber: false,
              })
              this.errorsStep3Text.push({
                streetAddress: "",
                city: "",
                postCode: "",
                country: "",
                accountNumber: "",
              })
              this.imgHolder[`${productTemplate.id}`] = [];
              this.pdfHolder[`${productTemplate.id}`] = [];
              this.complaintTemplate.products.push(productTemplate);
              this.checkExpectations()
              this.$store.commit("resetGlobalLoader");
          }else{
            await this.downloadComplaintData();
          }
              
        },
        computed:
        {
          checkSteps() {
            let preparedSteps
            let booleanSteps = this.steps[2].name === "Delivery" && !this.$store.state.portalSettings.fields.deliveryChargeback.cityInput.isShowed && !this.$store.state.portalSettings.fields.deliveryChargeback.countryInput.isShowed && !this.$store.state.portalSettings.fields.deliveryChargeback.postCodeInput.isShowed && !this.$store.state.portalSettings.fields.deliveryChargeback.streetAddressInput.isShowed
            if(this.steps[2].name === "Delivery" && booleanSteps) {
              preparedSteps = this.steps.slice(0,2)
            } else {
              preparedSteps = this.steps 
            }
            
            return { preparedSteps, booleanSteps}
          },
          checkSameDeliveryAddress() {
            return (this.$store.state.portalSettings.fields.credentials.countryInput.isShowed || this.$store.state.portalSettings.fields.credentials.cityInput.isShowed || this.$store.state.portalSettings.fields.credentials.streetAddressInput.isShowed || this.$store.state.portalSettings.fields.credentials.postCodeInput.isShowed)
          }
        },
        mounted() {
        }
    }
</script>
<style lang="scss" scoped>
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.5s ease;
  }

  .fade-enter-from,
  .fade-leave-to {
    opacity: 0;
  }
  .goals-box {
  max-height: 60rem;
  overflow: hidden;
  transition: all 0.5s ease;
}

.goals-box.expand {
  max-height: 4.5rem;
}
.expand-icon {
  width: 1.5rem;
  height: 1.5rem;
  transition: transform .3s ease-in-out;
}
.expand-icon:hover {
  cursor: pointer;
  opacity: 0.5 !important;
}
.expand-icon.rotated {
  transform: rotate(180deg);
}
</style>