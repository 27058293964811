<template>
    <div class="min-h-full">
    <Popover as="header" class="bg-gradient-to-r from-purple-500 via-purple-500 to-fuchsia-600 pb-24" v-slot="{ open }">
      <div class="mx-auto max-w-3xl px-4 sm:px-6 lg:max-w-7xl lg:px-8">
        <div class="relative flex items-center justify-center py-8 lg:py-5 lg:justify-between">
          <!-- Logo -->
          <div class="absolute left-0 flex-shrink-0 lg:static">
              <span class="sr-only">Complaia App</span>
              <img class="h-8 w-auto" src="../assets/complaia.svg" alt="" />
          </div>

          <div class="hidden lg:ml-4 lg:flex lg:items-center lg:pr-0.5">
            <div class="relative ml-4 flex-shrink-0">
              <button @click="newComplaint()" type="button" class="transition ease-in-out duration-200 inline-flex items-center text-sm font-medium text-white hover:text-blue-200">
                Create new complaint
                <ChevronRightIcon class="-mr-1 ml-1 h-4 w-4" aria-hidden="true" />
              </button>
            </div>
          </div>


          <!-- Menu button -->
          <div class="absolute right-0 flex-shrink-0 lg:hidden">
            <!-- Mobile menu button -->
            <PopoverButton class="inline-flex items-center justify-center rounded-md bg-transparent p-2 text-indigo-200 hover:bg-white hover:bg-opacity-10 hover:text-white focus:outline-none focus:ring-2 focus:ring-white">
              <span class="sr-only">Open main menu</span>
              <MenuIcon v-if="!open" class="block h-6 w-6" aria-hidden="true" />
              <XIcon v-else class="block h-6 w-6" aria-hidden="true" />
            </PopoverButton>
          </div>
        </div>
        <div class="hidden border-t border-white border-opacity-20 py-5 lg:block">
          <div class="grid grid-cols-3 items-center gap-8">
            <div class="col-span-2">
              <nav class="flex space-x-4">
                <div @click="currentPage = 'complaintData'" :class="[currentPage === 'complaintData' ? 'text-white cursor-default' : 'text-indigo-100 cursor-pointer hover:bg-opacity-10', 'transition ease-in-out duration-200 text-sm font-medium rounded-md bg-white bg-opacity-0 px-3 py-2']" >Complaint</div>
                <div v-if="!emptyState && complaintData.type === 'complaint'" @click="openChat()" :class="[currentPage === 'chat' ? 'text-white cursor-default' : 'text-indigo-100 cursor-pointer hover:bg-opacity-10', 'transition ease-in-out duration-200 text-sm font-medium rounded-md bg-white bg-opacity-0 px-3 py-2']" >Chat </div>
                <div v-if="!emptyState && complaintData.type === 'buffer'" class="transition ease-in-out duration-200 cursor-default text-gray-300 flex items-center text-sm font-medium rounded-md bg-white bg-opacity-0 px-3 py-2" >Chat <LockClosedIcon class="h-4 w-4 ml-1 flex-shrink-0" aria-hidden="true" /></div>
              </nav>
            </div>

          </div>
        </div>
      </div>

      <TransitionRoot as="template" :show="open">
        <div class="lg:hidden">
          <TransitionChild as="template" enter="duration-150 ease-out" enter-from="opacity-0" enter-to="opacity-100" leave="duration-150 ease-in" leave-from="opacity-100" leave-to="opacity-0">
            <PopoverOverlay class="fixed inset-0 z-20 bg-black bg-opacity-25" />
          </TransitionChild>

          <TransitionChild as="template" enter="duration-150 ease-out" enter-from="opacity-0 scale-95" enter-to="opacity-100 scale-100" leave="duration-150 ease-in" leave-from="opacity-100 scale-100" leave-to="opacity-0 scale-95">
            <PopoverPanel focus class="absolute inset-x-0 top-0 z-30 mx-auto w-full max-w-3xl origin-top transform p-2 transition">
              <div class="divide-y divide-gray-200 rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
                <div class="pt-3 pb-2">
                  <div class="flex items-center justify-between px-4">
                    <div>
                      <img class="h-8 w-auto" src="../assets/complaia.svg" alt="" />
                    </div>
                    <div class="-mr-2">
                      <PopoverButton class="inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                        <span class="sr-only">Close menu</span>
                        <XIcon class="h-6 w-6" aria-hidden="true" />
                      </PopoverButton>
                    </div>
                  </div>
                  <div class="mt-3 space-y-1 px-2">
                    <PopoverButton class="w-full flex justify-start"><div @click="currentPage = 'complaintData'" class="block cursor-pointer rounded-md w-full px-3 py-2 text-base text-left font-medium text-gray-900 hover:bg-gray-100 hover:text-gray-800">Complaint</div></PopoverButton>
                    <PopoverButton class="w-full flex justify-start"><div v-if="!emptyState && complaintData.type === 'complaint'" @click="openChat()" class="block cursor-pointer rounded-md w-full px-3 py-2 text-base text-left font-medium text-gray-900 hover:bg-gray-100 hover:text-gray-800">Chat</div></PopoverButton>
                    <div v-if="!emptyState && complaintData.type === 'buffer'" class="cursor-pointer flex items-center rounded-md px-3 py-2 text-base font-medium text-gray-400">Chat <LockClosedIcon class="h-5 w-5 ml-1 flex-shrink-0" aria-hidden="true" /></div>
                    <div v-if="!emptyState" @click="newComplaint()" class="block cursor-pointer rounded-md px-3 py-2 text-base font-medium text-gray-900 hover:bg-gray-100 hover:text-gray-800">Create new complaint</div>
                  </div>
                </div>
              </div>
            </PopoverPanel>
          </TransitionChild>
        </div>
      </TransitionRoot>
    </Popover>
    <main v-if="loaded" class="-mt-24 pb-8">
      <div :class="{'mx-auto max-w-3xl sm:px-6 lg:max-w-7xl lg:px-8': true, 'px-4': currentPage !== 'chat', 'px-2': currentPage === 'chat' }">
        <h1 class="sr-only">Complaia App</h1>
        <!-- Main 3 column grid -->
        <div v-if="!emptyState" class="grid grid-cols-1 items-start gap-4 lg:grid-cols-3 lg:gap-8">
          <!-- Left column -->
          <div class="grid grid-cols-1 gap-4 lg:col-span-2 order-2 lg:order-none">
            <section aria-labelledby="section-1-title">
              <h2 class="sr-only" id="section-1-title">Complaint data</h2>
              <div class="overflow-hidden rounded-lg bg-white shadow">
                <div>
                  <!-- Your content -->
                  <!-- {{complaintData.attendant}} -->
                  <!-- {{complaintData.data}} -->
                    <div v-if="complaintData.type === 'buffer' && currentPage === 'complaintData'" class="overflow-hidden bg-white">
                    <div class="px-4 py-5 sm:px-6">
                      <div class="flex justify-between">
                        <div>
                        <h3 class="text-lg font-medium leading-6 text-gray-900">Complaint Information</h3>
                        <p class="mt-1 max-w-2xl text-sm text-gray-500">Details of your complaint.</p>
                        </div>
                        <div class="mt-auto mb-auto">
                          <div class=" cursor-default relative inline-flex items-center rounded-full border border-gray-300 px-3 py-0.5 text-sm">
                            <span class="absolute flex flex-shrink-0 items-center justify-center">
                              <span class="h-1.5 w-1.5 bg-indigo-500 rounded-full" aria-hidden="true" />
                            </span>
                            <span class="ml-3.5 font-medium text-gray-900">Submitted</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="border-t border-gray-200 px-4 py-5 sm:p-0">
                      <dl class="sm:divide-y sm:divide-gray-200">
                        <div v-if="complaintData.data.clientCredentials.name.length > 0" class="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                          <dt class="text-sm font-medium text-gray-500">Full name</dt>
                          <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">{{complaintData.data.clientCredentials.name}}</dd>
                        </div>
                        <div v-if="complaintData.data.clientCredentials.phone.length > 0" class="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                          <dt class="text-sm font-medium text-gray-500">Phone</dt>
                          <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">{{complaintData.data.clientCredentials.phone}}</dd>
                        </div>
                        <div v-if="complaintData.data.clientCredentials.email.length > 0" class="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                          <dt class="text-sm font-medium truncate text-gray-500">Email address</dt>
                          <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">{{complaintData.data.clientCredentials.email}}</dd>
                        </div>
                        <div v-if="complaintData.data.clientCredentials.accountNumber.length > 0" class="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                          <dt class="text-sm font-medium text-gray-500">Account number</dt>
                          <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">{{complaintData.data.clientCredentials.accountNumber}}</dd>
                        </div>
                        <div v-if="complaintData.data.clientCredentials.country.length > 0 || complaintData.data.clientCredentials.city.length > 0 || complaintData.data.clientCredentials.postCode.length > 0 || complaintData.data.clientCredentials.streetAddress.length > 0" class="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                          <dt class="text-sm font-medium text-gray-500">Address</dt>
                          <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                            <div class="flex text-sm">
                              <div class="flex flex-col">
                                <span v-if="complaintData.data.clientCredentials.country.length > 0">{{complaintData.data.clientCredentials.country}}</span>
                                <span v-if="complaintData.data.clientCredentials.city.length > 0 || complaintData.data.clientCredentials.postCode.length > 0">{{complaintData.data.clientCredentials.city}}{{complaintData.data.clientCredentials.city.length === 0 ? '' : complaintData.data.clientCredentials.postCode.length > 0 ? ', ' : ''}}{{complaintData.data.clientCredentials.postCode}}</span>
                                <span v-if="complaintData.data.clientCredentials.streetAddress.length > 0">{{complaintData.data.clientCredentials.streetAddress}}</span>
                              </div>
                            </div>
                          </dd>
                        </div>
                        <div v-if="complaintData.data.delivery.country.length > 0 || complaintData.data.delivery.city.length > 0 || complaintData.data.delivery.postCode.length > 0 || complaintData.data.delivery.streetAddress.length > 0" class="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                          <dt class="text-sm font-medium text-gray-500">Delivery address</dt>
                          <dd v-if="complaintData.data.delivery.country !== complaintData.data.clientCredentials.country || complaintData.data.delivery.city !== complaintData.data.clientCredentials.city || complaintData.data.delivery.postCode !== complaintData.data.clientCredentials.postCode || complaintData.data.delivery.streetAddress !== complaintData.data.clientCredentials.streetAddress" class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                            <div class="flex text-sm">
                              <div class="flex flex-col">
                                <span v-if="complaintData.data.delivery.country.length > 0">{{complaintData.data.delivery.country}}</span>
                                <span v-if="complaintData.data.delivery.city.length > 0 || complaintData.data.delivery.postCode.length > 0">{{complaintData.data.delivery.city}}{{complaintData.data.delivery.city.length === 0 ? '' : complaintData.data.delivery.postCode.length > 0 ? ', ' : ''}}{{complaintData.data.delivery.postCode}}</span>
                                <span v-if="complaintData.data.delivery.streetAddress.length > 0">{{complaintData.data.delivery.streetAddress}}</span>
                              </div>
                            </div>
                          </dd>
                          <dd v-else class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                            Same as address
                          </dd>
                        </div>
                        <!-- <div class="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                          <dt class="text-sm font-medium text-gray-500">Complaint ID</dt>
                          <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">{{complaintData.data.formId}}</dd>
                        </div> -->
                        <div>
                          <div class="overflow-hidden bg-white py-5 sm:px-6">
                            <dt class="text-sm font-medium text-gray-500">Complained product list</dt>
                                  <dd class="mt-1 text-sm text-gray-900">
                                    <div role="list" class="divide-y divide-gray-200 rounded-md border border-gray-200">
                            <div v-for="(product, index) in complaintData.data.products" :key="index" :class="{'border-t border-gray-200': index > 0, 'py-5 px-4 sm:px-6': true}">
                              <h3 class="text-md mb-4 font-medium leading-6 text-gray-900">Product #{{index + 1}}</h3>
                              <dl class="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                                <div v-if="product.name.length > 0" class="sm:col-span-1">
                                  <dt class="text-sm font-medium text-gray-500">Product name</dt>
                                  <dd class="mt-1 text-sm text-gray-900 truncate">{{product.name}}</dd>
                                </div>
                                <div v-if="product.expectation.name.length > 0 || product.externalId.value.length > 0" class="sm:col-span-1">
                                  <dl class="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                                    <div v-if="product.expectation.name.length > 0" class="sm:col-span-1">
                                      <dt class="text-sm font-medium text-gray-500">Expectation</dt>
                                      <dd class="mt-1 text-sm text-gray-900 truncate">{{product.expectation.name}}</dd>
                                    </div>
                                    <div v-if="product.externalId.value.length > 0" class="sm:col-span-1">
                                      <dt class="text-sm font-medium text-gray-500">{{product.externalId.type}}</dt>
                                      <dd class="mt-1 text-sm text-gray-900 truncate">{{product.externalId.value}}</dd>
                                    </div>
                                  </dl>
                                </div>
                                <div v-if="product.problemDescription.length > 0" class="sm:col-span-2">
                                  <dt class="text-sm font-medium text-gray-500">Problem description</dt>
                                  <dd class="mt-1 text-sm text-gray-900">{{product.problemDescription.length > 0 ? product.problemDescription : "-"}}</dd>
                                </div>
                                <div class="sm:col-span-2">
                                  <dt class="text-sm font-medium text-gray-500">Attachments</dt>
                                  <dd class="mt-1 text-sm text-gray-900">
                                    <ul role="list" class="divide-y divide-gray-200 rounded-md border border-gray-200">
                                      <li v-for="(image, imgIndex) in product.files.images" :key="imgIndex" class="flex items-center justify-between py-3 pl-3 pr-4 text-sm">
                                        <div class="flex w-0 flex-1 items-center">
                                          <PaperClipIcon class="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                                          <span class="ml-2 w-0 flex-1 truncate">Image file #{{imgIndex + 1}}</span>
                                        </div>
                                        <div class="ml-4 flex-shrink-0">
                                          <a :href="image.url" target=”_blank” class="transition ease-in-out duration-200 font-medium text-indigo-600 hover:text-indigo-500">View</a>
                                        </div>
                                      </li>
                                      <!-- <li v-if="product.files.pdf.url.length > 0" class="flex items-center justify-between py-3 pl-3 pr-4 text-sm">
                                        <div class="flex w-0 flex-1 items-center">
                                          <PaperClipIcon class="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                                          <span class="ml-2 w-0 flex-1 truncate">Pdf file #1</span>
                                        </div>
                                        <div class="ml-4 flex-shrink-0">
                                          <a :href="product.files.pdf.url" target=”_blank” class="transition ease-in-out duration-200 font-medium text-indigo-600 hover:text-indigo-500">View</a>
                                        </div>
                                      </li> -->
                                      <li v-for="(pdf, pdfIndex) in product.files.pdfs" :key="pdfIndex" class="flex items-center justify-between py-3 pl-3 pr-4 text-sm">
                                        <div class="flex w-0 flex-1 items-center">
                                          <PaperClipIcon class="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                                          <span class="ml-2 w-0 flex-1 truncate">Pdf file #{{pdfIndex + 1}}</span>
                                        </div>
                                        <div class="ml-4 flex-shrink-0">
                                          <a :href="pdf.url" target=”_blank” class="transition ease-in-out duration-200 font-medium text-indigo-600 hover:text-indigo-500">View</a>
                                        </div>
                                      </li>
                                      <li v-if="product.files.images.length < 1 && product.files.pdfs.length < 1" class="flex items-center opacity-50 justify-between py-3 pl-3 pr-4 text-sm">
                                        <div class="flex w-0 flex-1 items-center">
                                          <PaperClipIcon class="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                                          <span class="ml-2 w-0 flex-1 truncate">No attachments</span>
                                        </div>
                                      </li>
                                    </ul>
                                  </dd>
                                </div>
                              </dl>
                            </div>
                            </div>
                            </dd>
                          </div>
                        </div>
                      </dl>
                    </div>
                  </div>
                  <div v-if="complaintData.type === 'complaint' && currentPage === 'complaintData'" class="overflow-hidden bg-white">
                    <div class="px-4 py-5 sm:px-6">
                      <div class="flex justify-between">
                        <div>
                        <h3 class="text-lg font-medium leading-6 text-gray-900">Complaint Information</h3>
                        <p class="mt-1 max-w-2xl text-sm text-gray-500">Details of your complaint.</p>
                        </div>
                        <div v-if="!complaintData.data.completed" class="mt-auto mb-auto">
                          <div class=" cursor-default relative inline-flex items-center rounded-full border border-gray-300 px-3 py-0.5 text-sm">
                            <span class="absolute flex flex-shrink-0 items-center justify-center">
                              <span class="h-1.5 w-1.5 bg-yellow-500 rounded-full" aria-hidden="true" />
                            </span>
                            <span class="ml-3.5 font-medium text-gray-900">In progress</span>
                          </div>
                        </div>
                        <div v-if="complaintData.data.completed" class="mt-auto mb-auto">
                          <div class=" cursor-default relative inline-flex items-center rounded-full border border-gray-300 px-3 py-0.5 text-sm">
                            <span class="absolute flex flex-shrink-0 items-center justify-center">
                              <span class="h-1.5 w-1.5 bg-green-500 rounded-full" aria-hidden="true" />
                            </span>
                            <span class="ml-3.5 font-medium text-gray-900">Completed</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="border-t border-gray-200 px-4 py-5 sm:p-0">
                      <dl class="sm:divide-y sm:divide-gray-200">
                        <div v-if="complaintData.data.client.name.length > 0" class="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                          <dt class="text-sm font-medium text-gray-500">Full name</dt>
                          <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">{{complaintData.data.client.name}}</dd>
                        </div>
                        <div v-if="complaintData.data.client.phoneNum.length > 0" class="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                          <dt class="text-sm font-medium text-gray-500">Phone</dt>
                          <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">{{complaintData.data.client.phoneNum}}</dd>
                        </div>
                        <div v-if="complaintData.data.client.email.length > 0" class="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                          <dt class="text-sm font-medium text-gray-500">Email address</dt>
                          <dd class="mt-1 text-sm text-gray-900 truncate sm:col-span-2 sm:mt-0">{{complaintData.data.client.email}}</dd>
                        </div>
                        <div v-if="complaintData.data.chargeback.iban.length > 0" class="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                          <dt class="text-sm font-medium text-gray-500">Account number</dt>
                          <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">{{complaintData.data.chargeback.iban}}</dd>
                        </div>
                        <div v-if="complaintData.data.client.country.length > 0 || complaintData.data.client.city.length > 0 || complaintData.data.client.postCode.length > 0 || complaintData.data.client.address.length > 0" class="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                          <dt class="text-sm font-medium text-gray-500">Address</dt>
                          <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                            <div class="flex text-sm">
                              <div class="flex flex-col">
                                <span v-if="complaintData.data.client.country.length > 0">{{complaintData.data.client.country}}</span>
                                <span v-if="complaintData.data.client.city.length > 0 || complaintData.data.client.postCode.length > 0">{{complaintData.data.client.city}}{{complaintData.data.client.city.length === 0 ? '' :complaintData.data.client.postCode.length > 0 ? ', ' : ' '}}{{complaintData.data.client.postCode}}</span>
                                <span v-if="complaintData.data.client.address.length > 0">{{complaintData.data.client.address}}</span>
                              </div>
                            </div>
                          </dd>
                        </div>
                        <div v-if="complaintData.data.delivery.country.length > 0 || complaintData.data.delivery.city.length > 0 || complaintData.data.delivery.postCode.length > 0 || complaintData.data.delivery.address.length > 0" class="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                          <dt class="text-sm font-medium text-gray-500">Delivery address</dt>
                          <dd v-if="complaintData.data.client.country !== complaintData.data.delivery.country || complaintData.data.client.city !== complaintData.data.delivery.city || complaintData.data.client.postCode !== complaintData.data.delivery.postCode || complaintData.data.client.address !== complaintData.data.delivery.address" class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                            <div class="flex text-sm">
                              <div class="flex flex-col">
                                <span v-if="complaintData.data.delivery.country.length > 0">{{complaintData.data.delivery.country}}</span>
                                <span v-if="complaintData.data.delivery.city.length > 0 || complaintData.data.delivery.postCode.length > 0">{{complaintData.data.delivery.city}}{{complaintData.data.delivery.city.length === 0 ? '' : complaintData.data.delivery.postCode.length > 0 ? ', ' : ''}}{{complaintData.data.delivery.postCode}}</span>
                                <span v-if="complaintData.data.delivery.address.length > 0">{{complaintData.data.delivery.address}}</span>
                              </div>
                            </div>
                          </dd>
                          <dd v-else class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                            Same as address
                          </dd>
                        </div>
                        <div v-if="complaintData.data.id" class="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                          <dt class="text-sm font-medium text-gray-500">Complaint ID</dt>
                          <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">{{complaintData.data.id}}</dd>
                        </div>
                        <div>
                          <div class="overflow-hidden bg-white py-5 sm:px-6">
                            <dt class="text-sm font-medium text-gray-500">Complained product list</dt>
                                  <dd class="mt-1 text-sm text-gray-900">
                                    <div role="list" class="divide-y divide-gray-200 rounded-md border border-gray-200">
                                      <!-- 'bg-red-50': product.acceptance !== 'accepted', -->
                            <div v-for="(product, index) in complaintData.data.cases" :key="index" :class="{'border-t border-gray-200': index > 0, 'py-5 px-4 sm:px-6': true}">
                              <dl class="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-4">
                                <div class="col-span-1 sm:col-span-3">
                                  <h3 class="text-md mb-4 font-medium leading-6 text-gray-900">Product #{{index + 1}}</h3>
                                </div>
                                <div class="ml-auto">
                                  <div v-if="complaintData.data.completed" class="mt-auto mb-auto">
                                    <div v-if="product.acceptance !== 'accepted'" class=" cursor-default relative inline-flex items-center rounded-full border border-gray-300 bg-white px-3 py-0.5 text-sm">
                                      <span class="absolute flex flex-shrink-0 items-center justify-center">
                                        <span class="h-1.5 w-1.5 bg-red-500 rounded-full" aria-hidden="true" />
                                      </span>
                                      <span class="ml-3.5 font-medium text-gray-900">Canceled</span>
                                    </div>
                                    <div v-if="product.acceptance === 'accepted'" class=" cursor-default relative inline-flex items-center rounded-full border border-gray-300 px-3 py-0.5 text-sm">
                                      <span class="absolute flex flex-shrink-0 items-center justify-center">
                                        <span class="h-1.5 w-1.5 bg-green-500 rounded-full" aria-hidden="true" />
                                      </span>
                                      <span class="ml-3.5 font-medium text-gray-900">Accepted</span>
                                    </div>
                                  </div>
                                </div>
                              </dl>
                              <div v-if="complaintData.data.completed" :class="[product.acceptance !== 'accepted' ? 'bg-red-50' : 'bg-green-50','rounded-md p-4 mt-1 mb-6']">
                                  <div>
                                      <dt :class="[product.acceptance !== 'accepted' ? 'text-red-800 border-red-100' : 'text-green-800 border-green-100','text-base font-medium border-b pb-2 mb-3']">Attendant decision</dt>
                                      <dl class="grid grid-cols-1 gap-x-4 gap-y-4 sm:grid-cols-2">
                                        <div class="sm:col-span-1">
                                          <dt :class="[product.acceptance !== 'accepted' ? 'text-red-800' : 'text-green-800','text-sm font-medium']">Decision</dt>
                                          <dd :class="[product.acceptance !== 'accepted' ? 'text-red-700' : 'text-green-700','mt-1 text-sm']">{{product.action}}</dd>
                                        </div>
                                        <div class="sm:col-span-1">
                                          <dt :class="[product.acceptance !== 'accepted' ? 'text-red-800' : 'text-green-800','text-sm font-medium']">Reason for decision</dt>
                                          <dd :class="[product.acceptance !== 'accepted' ? 'text-red-700' : 'text-green-700','mt-1 text-sm']">{{product.reason}}</dd>
                                        </div>
                                        <div class="sm:col-span-2">
                                          <dt :class="[product.acceptance !== 'accepted' ? 'text-red-800' : 'text-green-800','text-sm font-medium']">Description from attendant</dt>
                                          <dd :class="[product.acceptance !== 'accepted' ? 'text-red-700' : 'text-green-700','mt-1 text-sm']">NOT READY YET</dd>
                                        </div>
                                      </dl>
                                  </div>
                              </div>
                              <dl class="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                                <div v-if="product.item.name.length > 0" class="sm:col-span-1">
                                  <dt class="text-sm font-medium text-gray-500">Product name</dt>
                                  <dd class="mt-1 text-sm text-gray-900 truncate">{{product.item.name}}</dd>
                                </div>
                                <div class="sm:col-span-1">
                                  <dl class="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                                    <div v-if="product.preferredActionByClient.length > 0" class="sm:col-span-1">
                                      <dt class="text-sm font-medium text-gray-500">Expectation</dt>
                                      <!-- <dd v-if="product.completed" class="mt-1 text-sm text-gray-900">{{product.action}}</dd>
                                      <dd v-if="!product.completed" class="mt-1 text-sm text-gray-900">No decision yet</dd> -->
                                      <dd class="mt-1 text-sm text-gray-900 truncate">{{product.preferredActionByClient}}</dd>
                                    </div>
                                    <div v-if="complaintData.data.externalIds.invoiceNum.length > 0" class="sm:col-span-1">
                                      <dt class="text-sm font-medium text-gray-500">Invoice number</dt>
                                      <dd class="mt-1 text-sm text-gray-900 truncate">{{complaintData.data.externalIds.invoiceNum}}</dd>
                                    </div>
                                  </dl>
                                </div>
                                <div class="sm:col-span-2">
                                  <dt class="text-sm font-medium text-gray-500">Problem description</dt>
                                  <dd class="mt-1 text-sm text-gray-900">{{product.description.length > 0 ? product.description : "-"}}</dd>
                                </div>
                                <div class="sm:col-span-2">
                                  <dt class="text-sm font-medium text-gray-500">Attachments</dt>
                                  <dd class="mt-1 text-sm text-gray-900">
                                    <ul role="list" class="divide-y divide-gray-200 rounded-md border border-gray-200">
                                      <li v-for="(image, imgIndex) in product.files.images" :key="imgIndex" class="flex items-center justify-between py-3 pl-3 pr-4 text-sm">
                                        <div class="flex w-0 flex-1 items-center">
                                          <PaperClipIcon class="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                                          <span class="ml-2 w-0 flex-1 truncate">Image file #{{imgIndex + 1}}</span>
                                        </div>
                                        <div class="ml-4 flex-shrink-0">
                                          <a :href="image.url" target=”_blank” class="transition ease-in-out duration-200 font-medium text-indigo-600 hover:text-indigo-500">View</a>
                                        </div>
                                      </li>
                                      <li v-for="(pdf, pdfIndex) in product.files.pdfs" :key="pdfIndex" class="flex items-center justify-between py-3 pl-3 pr-4 text-sm">
                                        <div class="flex w-0 flex-1 items-center">
                                          <PaperClipIcon class="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                                          <span class="ml-2 w-0 flex-1 truncate">Pdf file #{{pdfIndex + 1}}</span>
                                        </div>
                                        <div class="ml-4 flex-shrink-0">
                                          <a :href="pdf.url" target=”_blank” class="transition ease-in-out duration-200 font-medium text-indigo-600 hover:text-indigo-500">View</a>
                                        </div>
                                      </li>
                                      <li v-if="product.files.images.length < 1 && product.files.pdfs.length < 1" class="flex items-center opacity-50 justify-between py-3 pl-3 pr-4 text-sm">
                                        <div class="flex w-0 flex-1 items-center">
                                          <PaperClipIcon class="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                                          <span class="ml-2 w-0 flex-1 truncate">No attachments</span>
                                        </div>
                                      </li>
                                    </ul>
                                  </dd>
                                </div>
                                <!-- <div class="sm:col-span-2 border-t border-gray-200 pt-6">
                                  <dl class="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                                    <div class="sm:col-span-1">
                                      <dt class="text-sm font-medium text-gray-500">Problem description</dt>
                                      <dd class="mt-1 text-sm text-gray-900">{{product.description}}</dd>
                                    </div>
                                    <div class="sm:col-span-1">
                                      <dt class="text-sm font-medium text-gray-500">Problem description</dt>
                                      <dd class="mt-1 text-sm text-gray-900">{{product.description}}</dd>
                                    </div>
                                  </dl>
                                </div>
                                <div class="sm:col-span-2">
                                      <dt class="text-sm font-medium text-gray-500">Problem description</dt>
                                      <dd class="mt-1 text-sm text-gray-900">{{product.description}}</dd>
                                </div> -->
                              </dl>
                            </div>
                            </div>
                            </dd>
                          </div>
                        </div>
                      </dl>
                    </div>
                  </div>
                  <div v-if="complaintData.type === 'complaint' && currentPage === 'chat'" class="overflow-hidden bg-white">
                    <div>
                      <div class="flex justify-between">
                        <div class="mx-auto min-w-full">
                          <div class="w-full flex justify-between bg-gray-100 text-gray-700 border-b border-gray-200 rounded-t-lg px-4 py-2">
                            <div class="flex items-center text-sm pr-2 sm:text-base">
                              <img class="inline-block border-gray-300 border h-8 w-8 rounded-full mr-2" :src="complaintData.attendant.photo" alt="" />
                              <span class="font-medium truncate">{{complaintData.company.name}}</span>
                            </div>
                            <div class="flex items-center">
                              <XIcon @click="currentPage = 'complaintData'"  class="transition ease-in-out duration-200 h-5 w-5 hover:text-indigo-500 cursor-pointer" aria-hidden="true" />
                            </div>
                          </div>
                          <div ref="chat" class="mx-auto min-h-mobileChat max-h-mobileChat lg:min-h-1 lg:max-h-chat min-w-full p-3 overflow-y-scroll rounded-md ">
                            <button @click="scrollToNewMessage()" v-if="complaintData.chat.messages.length < 1" type="button" class="transition ease-in-out duration-200 text-gray-500 hover:text-gray-700 cursor-pointer relative block w-full rounded-lg border-2 border-dashed border-gray-300 hover:border-gray-400 p-4 text-center">
                              <ChatAlt2Icon class="mx-auto h-8 w-8 opacity-60"  aria-hidden="true" />
                              <span class="mt-2 block text-sm font-normal">Start chat with your attendant.</span>
                            </button>
                            <div v-for="(message, index) in complaintData.chat.messages" :key="index">
                              <div v-if="!message.customer" :class="{'flex max-w-full sm:max-w-chat': true, 'mt-4': index > 0}">
                                <div class="py-3 pl-4 pr-4 rounded-tl-lg rounded-bl-lg flex-shrink-0 bg-indigo-200">
                                  <div>
                                    <img class="inline-block border-gray-300 border h-8 w-8 rounded-full" :src="message.image" alt="" />
                                  </div>
                                </div>
                                <div class="bg-indigo-200 rounded-tr-lg rounded-br-lg pr-4 py-3">
                                  <h4 class="text-sm font-medium text-gray-500">{{message.name}}</h4>
                                  <p class="text-sm text-gray-900">{{message.message}}</p>
                                </div>
                                <div class="flex justify-start items-center"><span class="text-gray-300 text-xs ml-4">{{translateUnix(message.date)}}</span></div>
                              </div>
                              <div v-if="message.customer" :class="{'flex justify-end max-w-full sm:max-w-chat ml-auto': true, 'mt-4': index > 0}">
                                <div class="flex justify-start items-center"><span class="text-gray-300 text-xs mr-4">{{translateUnix(message.date)}}</span></div>
                                <div class="flex flex-row-reverse">
                                  <div class="py-3 pl-4 pr-4 rounded-tr-lg rounded-br-lg flex-shrink-0 bg-indigo-50">
                                    <div>
                                      <img class="inline-block border-gray-300 border h-8 w-8 rounded-full" :src="message.image" alt="" />
                                    </div>
                                  </div>
                                  <div class="bg-indigo-50 rounded-tl-lg rounded-bl-lg pl-4 py-3">
                                    <h4 class="text-sm text-right font-medium text-gray-500">{{message.name}}</h4>
                                    <p class="text-sm text-gray-900">{{message.message}}</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <!-- Content goes here -->
                          <div class="flex items-start space-x-4 px-4 py-4 bg-gray-50 border-t border-gray-200">
                            <div class="flex-shrink-0">
                              <img class="inline-block h-10 w-10 border-gray-300 border rounded-full" :src="`https://avatars.dicebear.com/api/initials/${encodeURIComponent(complaintData.data.client.name)}.svg`" alt="" />
                            </div>
                            <div class="min-w-0 flex-1">
                              <form>
                                <div class="transition ease-in-out duration-300 border-b focus-within:rounded-md border-gray-200 hover:border-gray-50 focus-within:border-gray-50">
                                  <label for="comment" class="sr-only">Your message</label>
                                  <textarea id="chatInputDesktop" v-on:keypress.enter="sendMessage()" rows="3" v-model="this.chatMessage" class="transition ease-in-out duration-300 p-2 rounded-md hidden lg:block w-full bg-gray-50 resize-none border border-transparent pb-2 focus:border-indigo-600 focus:ring-0 text-sm hover:bg-white focus:bg-white hover:border-gray-200" placeholder="Your message..." />
                                  <textarea id="chatInputMobile" v-on:keypress.enter="sendMessage()" rows="1" v-model="this.chatMessage" class="transition ease-in-out duration-300 p-2 rounded-md block lg:hidden w-full bg-gray-50 resize-none border border-transparent focus:border-indigo-600 focus:ring-0 hover:bg-white focus:bg-white hover:border-gray-200 text-sm" placeholder="Your message..." />
                                </div>
                                <div class="flex justify-end pt-2">
                                  <!-- <div class="flex items-center space-x-5">
                                    <div class="flow-root">
                                      <button type="button" class="-m-2 inline-flex h-10 w-10 items-center justify-center rounded-full text-gray-400 hover:text-gray-500">
                                        <PaperClipIcon class="h-6 w-6" aria-hidden="true" />
                                        <span class="sr-only">Attach a file</span>
                                      </button>
                                    </div>
                                  </div> -->
                                  <div class="flex-shrink-0">
                                    <button ref="button" @click.prevent="sendMessage()" :disabled="chatBlock" :class="{'inline-flex items-center rounded-md border border-transparent px-4 py-2 text-sm font-medium text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2': true, 'bg-indigo-600 hover:bg-indigo-700': !chatBlock, 'bg-gray-500': chatBlock}">
                                      Send
                                      <PaperAirplaneIcon class="-mr-1 ml-2 h-4 w-4 transform rotate-90" aria-hidden="true" />
                                      </button>
                                  </div>
                                </div>
                                <div class="flex justify-end pt-2">
                                  <div v-if="chatError" class="inline-flex items-center text-xs font-medium text-red-600">
                                        {{this.chatErrorContent}}
                                  </div>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- <div class="border-t border-gray-200 px-4 py-5 sm:p-0">
                      test
                    </div> -->
                  </div>
                  
                </div>
              </div>
            </section>
          </div>

          <!-- Right column -->
          <div :class="{'grid grid-cols-1 gap-4': true, 'hidden lg:grid': currentPage === 'chat'}">
            <section aria-labelledby="section-2-title" class="grid">
              <h2 class="sr-only" id="section-2-title">Status</h2>
              <div class="overflow-hidden rounded-lg bg-white shadow">
                <div class="p-6">
                  <!-- Your content -->
                    <h1 id="message-heading" class="text-lg font-medium text-gray-900 truncate">{{complaintData.company.name}}</h1>
                    <div class="flex mt-1 text-sm items-center text-gray-500">
                    <ReceiptTaxIcon class="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                            <span class="truncate">{{complaintData.company.taxIdNum}}</span>
                    </div>
                    <div class="flex mt-2 text-sm text-gray-500">
                    <LocationMarkerIcon class="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                        <div class="flex flex-col">
                            <span class="truncate">{{complaintData.company.country}}</span>
                            <span class="truncate">{{complaintData.company.city}}, {{complaintData.company.postalCode}}</span>
                            <span class="truncate">{{complaintData.company.state}}</span>
                            <span class="truncate">{{complaintData.company.address}}</span>
                        </div>
                    </div>
                </div>
              </div>
              <div class="overflow-hidden mt-4 rounded-lg bg-white shadow">
                <div class="p-6">
                  <!-- Your content -->
                    <div class="flow-root">
                        <ul role="list" class="-mb-8">

                        <li>
                        <div class="relative pb-8">
                            <!-- ten do wyjebania -->
                            <span class="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true"></span>
                            <div class="relative flex space-x-3">
                            <div>
                                <span class="h-8 w-8 rounded-full bg-green-500 flex items-center justify-center ring-8 ring-white">
                                <svg class="h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                    <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                                </svg>
                                </span>
                            </div>
                            <div class="min-w-0 flex-1 flex justify-between space-x-4">
                                <div>
                                    <p class="text-xs text-gray-900 font-medium">Submitted</p>
                                    <p class="text-xs text-gray-500">The complaint was reported to the service.</p>
                                </div>
                                <div v-if="complaintData.type === 'buffer'" class="whitespace-nowrap text-right text-xxs flex justify-center items-center text-gray-500">
                                  {{ translate(complaintData.data.createdDate.asText) }}
                                </div>
                                <div v-if="complaintData.type === 'complaint'" class="whitespace-nowrap text-right text-xxs flex justify-center items-center text-gray-500">
                                  {{ translate(complaintData.data.portalData.submitDate) }}
                                </div>
                            </div>
                            </div>
                        </div>
                        </li>
                        <li>
                        <div class="relative pb-8">
                            <span class="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true"></span>
                            <div class="relative flex space-x-3">
                            <div v-if="complaintData.type === 'complaint'">
                                <span class="h-8 w-8 rounded-full bg-green-500 flex items-center justify-center ring-8 ring-white">
                                <svg class="h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                    <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                                </svg>
                                </span>
                            </div>
                            <div v-if="complaintData.type !== 'complaint'">
                                <span class="h-8 w-8 rounded-full border-2 bg-white flex items-center justify-center ring-8 ring-white">
                                </span>
                            </div>
                            <div class="min-w-0 flex-1 flex justify-between space-x-4">
                                <div>
                                    <p class="text-xs text-gray-900 font-medium">Processing</p>
                                    <p class="text-xs text-gray-500">The complaint is processed.</p>
                                </div>
                                <div v-if="complaintData.type === 'complaint'" class="whitespace-nowrap text-right text-xxs flex justify-center items-center text-gray-500">
                                  {{ translate(complaintData.data.createdDate.asText) }}
                                </div>
                            </div>
                            </div>
                        </div>
                        </li>
                        <li>
                        <div class="relative pb-8">
                            <!-- ten do wyjebania -->
                            <div class="relative flex space-x-3">
                            <div v-if="complaintData.type === 'complaint' && complaintData.data.completed">
                                <span class="h-8 w-8 rounded-full bg-green-500 flex items-center justify-center ring-8 ring-white">
                                <svg class="h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                    <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                                </svg>
                                </span>
                            </div>
                            <div v-if="complaintData.type !== 'complaint' || (complaintData.type === 'complaint' &&  !complaintData.data.completed)">
                                <span class="h-8 w-8 rounded-full border-2 bg-white flex items-center justify-center ring-8 ring-white">
                                </span>
                            </div>
                            <div class="min-w-0 flex-1 flex justify-between space-x-4">
                                <div>
                                    <p class="text-xs text-gray-900 font-medium">Closed</p>
                                    <p class="text-xs text-gray-500">The complaint has been resolved.</p>
                                </div>
                                <div v-if="complaintData.type === 'complaint' && complaintData.data.portalData.completeDate !== null" class="whitespace-nowrap text-right text-xxs flex justify-center items-center text-gray-500">
                                  {{ translate(complaintData.data.portalData.completeDate) }}
                                </div>
                            </div>
                            </div>
                        </div>
                        </li>

                    </ul>
                    </div>
                </div>
              </div>
              <!-- Tutaj będzię v-if="complaintData.type === 'complaint'" -->
              <div v-if="complaintData.type === 'complaint'" class="overflow-hidden mt-4 rounded-lg bg-white shadow">
                <div class="col-span-1 divide-y divide-gray-200 rounded-lg bg-white shadow">
                  <!-- Your content -->
                  <div class="flex w-full items-center justify-between space-x-6 p-6">
                    <div class="flex-1 truncate">
                    <div class="flex items-center space-x-3">
                        <h3 class="truncate text-sm font-medium text-gray-900">{{complaintData.attendant.fullName}}</h3>
                        <span class="inline-block flex-shrink-0 rounded-full bg-green-100 px-2 py-0.5 text-xs font-medium text-green-800">Attendant</span>
                    </div>
                    <p class="mt-1 truncate text-sm text-gray-500">is handling your complaint</p>
                    </div>
                    <img class="h-10 w-10 flex-shrink-0 rounded-full bg-gray-300" :src="complaintData.attendant.photo" alt="" />
                </div>
                <div>
                    <div class="-mt-px flex divide-x divide-gray-200">
                    <div class="flex w-0 flex-1">
                        <a :href="`mailto:${complaintData.attendant.email}`" class="transition ease-in-out duration-200 cursor-pointer relative w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-bl-lg hover:text-white hover:bg-indigo-400">
                        <MailIcon class="h-5 w-5 opacity-60" aria-hidden="true" />
                        <span class="ml-3">Email</span>
                        </a>
                    </div>
                    <div class="-ml-px flex w-0 flex-1">
                        <div @click="openChat()" class="transition ease-in-out duration-200 cursor-pointer relative w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-br-lg hover:text-white hover:bg-indigo-400">
                        <ChatIcon class="h-5 w-5 opacity-60" aria-hidden="true" />
                        <span class="ml-3">Chat</span>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
              </div>
            </section>
          </div>
        </div>
        <div v-if="emptyState" class="grid grid-cols-1 items-start gap-4 lg:grid-cols-3 lg:gap-8">
          <div class="grid grid-cols-1 gap-4 lg:col-span-2 order-2 lg:order-none">
            <div class="overflow-hidden rounded-lg bg-white shadow">
              <div class="px-4 py-32 sm:px-6">
                <div class="mx-auto flex w-full max-w-7xl flex-grow flex-col justify-center px-4 sm:px-6 lg:px-8">
                  <!-- <div class="flex flex-shrink-0 justify-center">
                    <a href="/" class="inline-flex">
                      <span class="sr-only">Your Company</span>
                      <img class="h-12 w-auto" src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600" alt="" />
                    </a>
                  </div> -->
                  <div>
                    <div class="text-center">
                      <p class="text-base font-semibold text-indigo-600">Complaint not found.</p>
                      <h1 class="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">Sorry, we couldn’t find the complaint you’re looking for.</h1>
                      <p class="mt-2 text-base text-gray-500">Check your complaint address.</p>
                      <!-- <div class="mt-6">
                        <a href="#" class="text-base font-medium text-indigo-600 hover:text-indigo-500">
                          Go back home
                          <span aria-hidden="true"> &rarr;</span>
                        </a>
                      </div> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="grid grid-cols-1 gap-4 lg:col-span-1 order-2 lg:order-none">
            <div class="overflow-hidden rounded-lg bg-white shadow">
              <div class="px-4 py-5 sm:px-6">
                <div class="text-center">
                  <DocumentAddIcon class="mx-auto h-12 w-12 text-gray-400" aria-hidden="true" />
                  <h3 class="mt-2 text-sm font-medium text-gray-900">{{this.complaintData.company.name}}</h3>
                  <p class="mt-1 text-sm text-gray-500">Create new complaint</p>
                  <div class="mt-2">
                    <button @click="newComplaint()" type="button" class="transition ease-in-out duration-200 inline-flex  items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                      New complaint
                      <ChevronRightIcon class="-mr-1 ml-1 h-4 w-4" aria-hidden="true" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
    <footer>
      <div class="mx-auto max-w-3xl px-4 sm:px-6 lg:max-w-7xl lg:px-8">
        <div class="border-t border-gray-200 py-2 lg:py-8 text-center text-sm text-gray-500 sm:text-left"><a class="text-sm block sm:inline" href="https://complaia.systems/">&copy; 2022 Powered by Complaia Systems.</a></div>
      </div>
    </footer>
  </div>
</template>

<script>

import {
  // Menu,
  // MenuButton,
  // MenuItem,
  // MenuItems,
  Popover,
  PopoverButton,
  PopoverOverlay,
  PopoverPanel,
  TransitionChild,
  TransitionRoot
} from '@headlessui/vue'
// import { XCircleIcon} from '@heroicons/vue/solid'
import { 
  MenuIcon,
  // BellIcon,
  XIcon,
  MailIcon,
  ChatIcon,
  LocationMarkerIcon,
  ReceiptTaxIcon,
  PaperClipIcon,
  ChevronRightIcon,
  DocumentAddIcon,
  PaperAirplaneIcon,
  ChatAlt2Icon,
  LockClosedIcon
  } from '@heroicons/vue/outline'
import moment from 'moment';
import axios from 'axios';

    export default { 
    name: "checkComplaint",
    props: ['complaintData'],
    components: {
        // Menu,
        // MenuButton,
        // MenuItem,
        // MenuItems,
        Popover,
        PopoverButton,
        PopoverOverlay,
        PopoverPanel,
        TransitionChild,
        TransitionRoot,
        MenuIcon,
        // BellIcon, 
        XIcon,
        MailIcon,
        ChatIcon,
        LocationMarkerIcon,
        ReceiptTaxIcon,
        PaperClipIcon,
        ChevronRightIcon,
        DocumentAddIcon,
        PaperAirplaneIcon,
        ChatAlt2Icon,
        LockClosedIcon
        // XCircleIcon
    },
        data()
        {
            return {
                currentPage: "complaintData",
                loaded: false,
                emptyState: false,
                chatBlock: false,
                chatMessage: "",
                chatError: false,
                chatErrorContent: ""
            }
        },
        watch: {
        },
        methods:
        {
          scrollToNewMessage(){
            this.$refs["button"].scrollIntoView({ behavior: "smooth" })
            // document.getElementById("chatInput").focus()
            if(screen.width < 1024){
              document.getElementById("chatInputMobile").focus()
              console.log('test')
            }else{
              document.getElementById("chatInputDesktop").focus()
              console.log('test')
            }
          },
          async sendMessage(){
            let message = {
              customer: true,
              date: null,
              image: `https://avatars.dicebear.com/api/initials/${encodeURIComponent(this.complaintData.data.client.name)}.svg`,
              message: this.chatMessage,
              name: this.complaintData.data.client.name
            }
            if(this.chatMessage.length > 0){
              this.chatBlock = true
              const result = await axios.post("https://europe-central2-complaia.cloudfunctions.net/api/chat/addMessage", {
                message: message,
                chatId: `${this.complaintData.data.id}`,
                portalLink: this.$route.query.portal
              })
              console.log(result.data.status)
              if(result.data.status === 'ok'){
                let unix = moment().unix()
                this.$emit('addMessage', message, unix)
                this.chatMessage = ""
                this.chatBlock = false
                setTimeout(() => {
                  let chat = this.$refs.chat
                  chat.scrollTo({top: 99999999999, behavior: 'smooth'})
                }, 50)
                this.chatError = false
                this.chatErrorContent = ""
              }else{
                this.chatBlock = false
                this.chatError = true
                this.chatErrorContent = "Somenthing went wrong! Can't send your message."
              }
            }else{
              this.chatError = true
              this.chatErrorContent = "Your message has no content."
              setTimeout(() => {
              this.chatMessage = ""
              }, 10)
            }
          },
          openChat(){
            this.currentPage = 'chat'
            setTimeout(() => {
              let chat = this.$refs.chat
              chat.scrollTo({top: 99999999999, behavior: 'smooth'})
            }, 50)
          },
          newComplaint(){
            this.$router.push(`/?portal=${this.$route.query.portal}`)
            setTimeout(() => {
              window.location.reload();
            }, 50)
            // /?portal=`${this.$route.query.portal}`
          },
          translate(date){
            let translated = moment(`${date}`, "YYYY-MM-DD").format("MMM Do YY");
            return translated
          },
          translateUnix(unix){
            let date = moment.unix(unix._seconds).calendar()
            return date
          },
          checkForData(){
            if(this.complaintData.data === undefined){
              setTimeout(() => {
                this.checkForData()
              }, 1000)
            }else{
              console.log(this.complaintData.data)
              if(this.complaintData.data !== null){
                this.emptyState = false
                this.loaded = true
                this.$store.commit("resetGlobalLoader");
              }else{
                this.emptyState = true
                this.loaded = true
                this.$store.commit("resetGlobalLoader");
              }
            }
          }
        },
        created()
        {
        },
        computed:
        {
            
        },
        mounted() {
          this.checkForData()
          // var objDiv = document.getElementById("chat");
          // objDiv.scrollTop = objDiv.scrollHeight;
        }
    }
</script>
<style lang="scss" scoped>
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.5s ease;
  }

  .fade-enter-from,
  .fade-leave-to {
    opacity: 0;
  }
  .goals-box {
  max-height: 60rem;
  overflow: hidden;
  transition: all 0.5s ease;
}

.goals-box.expand {
  max-height: 4.5rem;
}
.expand-icon {
  width: 1.5rem;
  height: 1.5rem;
  transition: transform .3s ease-in-out;
}
.expand-icon:hover {
  cursor: pointer;
  opacity: 0.5 !important;
}
.expand-icon.rotated {
  transform: rotate(180deg);
}
</style>